import React, { Component } from "react";
import { Button, ButtonGroup, Table, UncontrolledCollapse } from "reactstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import KSTKEmptyContent from "./KSTKEmptyContent";
import KSTKCustomColumnsValues from "./KSTKCustomColumnsValues";
import { KSTKSelect } from "./KSTKSelect";
import { getDataFromDatasource } from "../services/organization.service";
import { getModulesData } from "../services/module.service";
import { publishNotification } from "../actions/NotificationActions";
import "./KSTKReactDataGridStyling.css";

import { AgGridReact } from "ag-grid-react";
import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../../node_modules/ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import KSTKItemList from "./KSTKItemList";
import produce from "immer";

const mapDispatchToProps = (dispatch) => ({
  publishNotification: (metadata) => dispatch(publishNotification(metadata)),
});

const mapStateToProps = (state) => ({
  ...state,
});

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 45px calc(100% - 45px);
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  /* grid-column-start: 1;
  grid-column-end: 3; */
`;

const SourceTransformationPanel = styled.div`
  /* color: #fff; */
  /* background-color: #212529; */
  font-size: 12px;
  overflow: auto;
  padding: 5px 0 0 10px;
`;

const StyledUncontrolledCollapse = styled(UncontrolledCollapse)`
  height: 100%;
`;

const GridsWrapper = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 100%;
`;

const KSTKCustomColumnsValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-rows: 100%;
  padding: 5px;
`;

class KSTKReportSourcesEditor extends Component {
  constructor(props) {
    super(props);
    this.setSelectedSource = this.setSelectedSource.bind(this);
    this.convertDataTypes = this.convertDataTypes.bind(this);
    this.serialize = this.serialize.bind(this);
    this.onHeadersGridCellValueChanged = this.onHeadersGridCellValueChanged.bind(this);
    this.onModelationsDataTypesGridCellValueChanged = this.onModelationsDataTypesGridCellValueChanged.bind(this);
    this.handleCustomColumnsChange = this.handleCustomColumnsChange.bind(this);
    this.handlePrimaryKeyChange = this.handlePrimaryKeyChange.bind(this);
    this.getPrimaryKeyValue = this.getPrimaryKeyValue.bind(this);

    this.dataGridRef = React.createRef();
    this.headersGridRef = React.createRef();
    this.modelationsGridRef = React.createRef();

    this.getDataFromDatasource = getDataFromDatasource;
    this.getModulesData = getModulesData;

    const primaryKeys = {};
    if (props.datasource?.resources?.length > 0) {
      props.datasource.resources.forEach((r) => {
        primaryKeys[`${r.schema}.${r.name}`] = r.primaryKey;
      });
    }

    this.state = {
      refreshCount: 0,
      datasource: this.props.datasource || null,
      selected: null,
      columns: [],
      rows: [],
      customHeaders:
        this.props.datasource != null && this.props.datasource.customHeaders != null
          ? this.props.datasource.customHeaders
          : {},
      customModelations:
        this.props.datasource != null && this.props.datasource.customModelations != null
          ? this.props.datasource.customModelations
          : {},
      customDataTypes:
        this.props.datasource != null && this.props.datasource.customDataTypes != null
          ? this.props.datasource.customDataTypes
          : {},
      customColumns:
        this.props.datasource != null && this.props.datasource.customColumns != null
          ? this.props.datasource.customColumns
          : {},
      customHeadersRows: [],
      customModelationsRows: [],
      customDataTypesRows: [],
      primaryKeys: primaryKeys,
    };

    this.customHeadersColumns = [
      { field: "name", headerName: "ID" },
      { field: "header", headerName: "Header", editable: true },
    ];
    this.customModelationsColumns = [
      { field: "name", headerName: "ID" },
      { field: "mod", headerName: "Mod", editable: true },
    ];
    this.customDataTypesColumns = [
      { field: "name", headerName: "ID" },
      {
        field: "dataType",
        headerName: "Data type",
        editable: true,
        singleClickEdit: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["", "currency", "date", "decimal", "percentage"],
        },
      },
      {
        field: "decimal",
        headerName: "Decimal places",
        valueParser: (params) => (params.newValue != null && params.newValue != "" ? Number(params.newValue) : null),
        editable: true,
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (!nextProps.datasource || !nextProps.datasource.resources || !nextProps.datasource.resources.length) {
      this.setState({ selected: null });
    }
  }

  markSelectedSource(e) {
    e.target.parentElement.querySelectorAll("button").forEach((b) => {
      b.classList.remove("primary");
      b.classList.remove("btn-primary");
      b.classList.add("btn-secondary");
    });

    e.target.classList.add("primary");
    e.target.classList.add("btn-primary");
    e.target.classList.remove("btn-secondary");
  }

  setSelectedSource(r) {
    const cHeaders = this.state.customHeaders[r.id];
    const cModelations = this.state.customModelations[r.id];
    const cDataTypes = this.state.customDataTypes[r.id];
    const cCustomColumns = this.state.customColumns;

    this.setState({
      selected: r,
      columns: r.columns.map((c) => {
        let cObj = {
          field: c.name,
          headerName: c.name,
          width: this.getColumnWidth(c),
          resizable: true,
          valueGetter: (params) => {
            if (params.data["parsed_" + c.name] != null) {
              return params.data["parsed_" + c.name];
            } else {
              return params.data[c.name];
            }
          },
        };

        if (cHeaders) {
          const columnCustomHeader = cHeaders.find((h) => h.name === c.name);
          cObj = {
            ...cObj,
            ...(columnCustomHeader
              ? {
                  field: columnCustomHeader.name,
                  headerName: columnCustomHeader.header,
                }
              : {}),
          };
        }
        return cObj;
      }),
    });

    this.dataGridOptions = {};

    if (this.props.datasource.sourceType == "postgres") {
      this.getDataFromDatasource(this.props.selected.id, r.schema, r.name, 50)
        .then((data) => {
          let modelatedDataTypedData = JSON.parse(JSON.stringify(data));
          if (this.state.customModelations && this.state.customModelations[r.id]) {
            modelatedDataTypedData = modelatedDataTypedData.map((d) =>
              this.formatDataWithModelations(d, this.state.customModelations[r.id])
            );
          }
          if (this.state.customDataTypes && this.state.customDataTypes[r.id]) {
            modelatedDataTypedData = modelatedDataTypedData.map((d) =>
              this.formatDataWithDataTypes(d, this.state.customDataTypes[r.id])
            );
          }
          this.setState({
            rows: data,
            modelatedRows: modelatedDataTypedData,
          });

          // this.dataGridRef.current.api.sizeColumnsToFit();
        })
        .catch(
          function (err) {
            this.props.publishNotification({
              type: "alert",
              message: `Error (Datasource -> ${r.schema} ${r.name}): ${err?.error?.message || err}`,
            });
          }.bind(this)
        );
    } else if (this.props.datasource.sourceType == "mongo") {
      let moduleId = this.props.datasource.module != null ? this.props.datasource.module : this.props.selectedModule.id;
      let moduleTabIndex = this.props.datasource.tabIndex != null ? this.props.datasource.tabIndex : r.tabIndex;
      this.getModulesData(moduleId, this.props.selected.id, 50, moduleTabIndex, null, "ORG")
        .then((data) => {
          this.setState({
            rows: data,
            modelatedRows: data,
          });
        })
        .catch(
          function (err) {
            this.props.publishNotification({
              type: "alert",
              message: `Error (Datasource -> ${r.schema} ${r.name}): ${err?.error?.message || err}`,
            });
          }.bind(this)
        );
    }

    const sourceCustomHeaderRows = r.columns.map((c) => {
      return {
        ...c,
        ...(cHeaders ? cHeaders.find((h) => h.name === c.name) : {}),
      };
    });
    const sourceCustomModelationRows = r.columns.map((c) => {
      return {
        ...c,
        ...(cModelations ? cModelations.find((m) => m.name === c.name) : {}),
      };
    });
    let sourceCustomDataTypeRows = r.columns.map((c) => {
      return {
        ...c,
        ...(cDataTypes ? cDataTypes.find((m) => m.name === c.name) : {}),
      };
    });

    let sourceCustomColumns = cCustomColumns ? cCustomColumns : {};

    this.setState({
      customHeadersRows: sourceCustomHeaderRows,
      customModelationsRows: sourceCustomModelationRows,
      customDataTypesRows: sourceCustomDataTypeRows,
      customColumns: sourceCustomColumns,
    });
  }

  getColumnWidth(col) {
    switch (col.type) {
      case "number":
        return 100;
        break;
      case "string":
        return 300;
        break;
      default:
        return 100;
    }
  }

  formatDataWithModelations(data, modelations) {
    if (!modelations) {
      return data;
    }

    const row = JSON.parse(JSON.stringify(data));
    modelations.forEach((m) => {
      if (!m || !m.mod || !m.mod.length) {
        return data[m.name];
      }

      const value = data[m.name];
      data[m.name] = eval(m.mod);
    });

    return data;
  }

  formatDataWithDataTypes(data, dataTypes) {
    if (!dataTypes) {
      return data;
    }

    const row = JSON.parse(JSON.stringify(data));
    dataTypes.forEach((d) => {
      if (!d || !d.dataType || !d.dataType.length) {
        return data[d.name];
      }

      const value = data[d.name];
      data["parsed_" + d.name] = eval(this.convertDataTypes(d.dataType, d.decimal));
    });

    return data;
  }

  onDataGridReady(params) {
    this.dataGridApi = params.api;
    this.dataGridColumnApi = params.columnApi;

    // params.api.sizeColumnsToFit();
  }

  onHeadersGridCellValueChanged(e) {
    let newColumns = this.state.columns.map((c) => {
      if (c.field === e.data.name) {
        c.headerName = e.data.header;
      }

      return c;
    });

    this.setState({
      columns: newColumns,
    });

    if (!this.state.customHeaders[this.state.selected.id]) {
      const newCH = {};
      newCH[this.state.selected.id] = this.state.datasource.resources
        .find((d) => d.id === this.state.selected.id)
        .columns.map((c) => {
          return {
            name: c.name,
            header: c.name,
          };
        });
      this.setState({
        customHeaders: {
          ...this.state.customHeaders,
          ...newCH,
        },
      });
      this.props.onChange(
        {
          ...this.state.customHeaders,
          ...newCH,
        },
        null
      );
    }

    const oldCH = this.state.customHeaders[this.state.selected.id].map((ch) => {
      if (ch.name === e.data.name) {
        ch.header = e.data.header;
      }

      return ch;
    });
    const oldCHObj = {};
    oldCHObj[this.state.selected.id] = oldCH;

    this.setState({
      customHeaders: {
        ...this.state.customHeaders,
        ...oldCHObj,
      },
    });

    this.props.onChange(
      {
        ...this.state.customHeaders,
        ...oldCHObj,
      },
      null
    );

    this.setState({
      refreshCount: this.state.refreshCount + 1,
    });
  }

  onModelationsDataTypesGridCellValueChanged(e) {
    if (e.data.mod != null) {
      let found1 = false;
      if (!this.state.customModelations[this.state.selected.id]) {
        this.state.customModelations[this.state.selected.id] = [];
      }
      const newModelations = this.state.customModelations[this.state.selected.id].map((cm) => {
        if (cm.name === e.data.name) {
          found1 = true;
          cm.mod = e.data.mod;
        }
        return cm;
      });
      if (!found1) {
        newModelations.push(e.data);
      }
      const tempCM = {};
      tempCM[this.state.selected.id] = newModelations;

      this.setState({
        customModelations: {
          ...this.state.customModelations,
          ...tempCM,
        },
      });

      this.props.onChange(null, {
        ...this.state.customModelations,
        ...tempCM,
      });
    }

    if (e.data.dataType != null) {
      let found2 = false;
      if (!this.state.customDataTypes[this.state.selected.id]) {
        this.state.customDataTypes[this.state.selected.id] = [];
      }
      const newDataTypes = this.state.customDataTypes[this.state.selected.id]
        .filter((dt) => dt.dataType != null && dt.dataType != "")
        .map((dt) => {
          if (dt.name === e.data.name) {
            found2 = true;
            dt.dataType = e.data.dataType;
            dt.decimal = e.data.decimal;
          }
          return dt;
        });
      if (!found2 && e.data.dataType != null && e.data.dataType != "") {
        newDataTypes.push(e.data);
      }
      const tempDT = {};
      tempDT[this.state.selected.id] = newDataTypes;

      this.setState({
        customDataTypes: {
          ...this.state.customDataTypes,
          ...tempDT,
        },
      });

      this.props.onChange(null, null, {
        ...this.state.customDataTypes,
        ...tempDT,
      });
    }

    this.setState({
      modelatedRows: JSON.parse(JSON.stringify(this.state.rows))
        .map((d) => this.formatDataWithModelations(d, this.state.customModelations[this.state.selected.id]))
        .map((d) => this.formatDataWithDataTypes(d, this.state.customDataTypes[this.state.selected.id])),
      refreshCount: this.state.refreshCount + 1,
    });
  }

  convertDataTypes(dataType, decimal) {
    switch (dataType) {
      case "none":
        return "";
      case "currency":
        return "value + ' €'";
      case "date":
        return "new Date(value) instanceof Date && !isNaN(new Date(value)) ? new Date(value).toDateString() : value";
      case "decimal":
        return (
          "!isNaN(Number(value)) && value != null ? Math.round(Number(value) * (10**" +
          decimal +
          ")) / (10**" +
          decimal +
          ") : value"
        );
      case "percentage":
        return "!isNaN(Number(value)) && Number(value) <= 1 && Number(value) >= 0 && value != null ? Number(value) * 100 + '%' : value";
      default:
        break;
    }
  }

  serialize() {
    return {
      ...this.props.report[0],
      customHeaders: this.state.customHeaders,
      customModelations: this.state.customModelations,
      customDataTypes: this.state.customDataTypes,
    };
  }

  handleCustomColumnsChange(i, customColumn) {
    if (!this.state.customColumns[this.state.selected.id]) {
      this.state.customColumns[this.state.selected.id] = [];
    }

    this.state.customColumns[this.state.selected.id][i] = customColumn;

    let newCustomColumns = this.state.customColumns[this.state.selected.id];

    const tempCC = {};
    tempCC[this.state.selected.id] = newCustomColumns;

    this.setState({
      customColumns: {
        ...this.state.customColumns,
        ...tempCC,
      },
    });

    this.props.onChange(null, null, null, {
      ...this.state.customColumns,
      ...tempCC,
    });
  }

  addCustomColumn() {
    if (!this.state.customColumns[this.state.selected.id]) {
      this.state.customColumns[this.state.selected.id] = [];
    }

    this.state.customColumns[this.state.selected.id].push({});

    this.setState({
      customColumns: {
        ...this.state.customColumns,
      },
    });

    this.props.onChange(null, null, null, {
      ...this.state.customColumns,
    });
  }

  removeCustomColumn(i) {
    this.state.customColumns[this.state.selected.id].splice(i, 1);

    this.setState({
      customColumns: {
        ...this.state.customColumns,
      },
    });

    this.props.onChange(null, null, null, {
      ...this.state.customColumns,
    });
  }

  handlePrimaryKeyChange(newPrimaryKey) {
    const currentTable = `${this.state.selected.schema}.${this.state.selected.name}`;
    const newPrimaryKeys = produce(this.state.primaryKeys, (k) => {
      k[currentTable] = newPrimaryKey?.value;
    });
    this.setState({
      primaryKeys: newPrimaryKeys,
    });
    this.props.onChange(null, null, null, null, newPrimaryKeys);
  }

  getPrimaryKeyValue() {
    const currentTable = `${this.state.selected.schema}.${this.state.selected.name}`;
    const currentPrimaryKey = this.state.primaryKeys[currentTable];

    return { value: currentPrimaryKey, label: currentPrimaryKey };
  }

  render() {
    return (
      <Wrapper>
        <ButtonWrapper>
          <ButtonGroup onClick={this.markSelectedSource}>
            {this.props.datasource && this.props.datasource.resources
              ? this.props.datasource.resources
                  .filter((r) => {
                    return r.name.indexOf("qa_") != 0;
                  })
                  .map((r) => (
                    <Button key={r.id} onClick={() => this.setSelectedSource(r)}>
                      {r.name}
                    </Button>
                  ))
              : null}
          </ButtonGroup>
        </ButtonWrapper>
        {this.state.selected === null || this.state.selected === undefined ? (
          <KSTKEmptyContent message="please choose source" icon="table" />
        ) : (
          <GridsWrapper className="ag-theme-balham-dark">
            <div key={this.state.refreshCount}>
              <AgGridReact
                rowData={this.state.modelatedRows}
                ref={this.dataGridRef}
                gridOptions={this.dataGridOptions}
                columnDefs={this.state.columns}
                onGridReady={this.onDataGridReady}
              />
            </div>
            <SourceTransformationPanel>
              <h5 id="primaryKeysSection">Primary Keys</h5>
              <StyledUncontrolledCollapse toggler="#primaryKeysSection">
                <KSTKSelect
                  id="primaryKeySelect"
                  placeholder="Primary Key"
                  isMulti={false}
                  isSearchable="true"
                  isClearable="true"
                  options={this.state.columns.map((c) => {
                    return { value: c.field, label: c.field };
                  })}
                  onChange={(value) => this.handlePrimaryKeyChange(value)}
                  value={this.getPrimaryKeyValue()}
                  isDisabled={false}
                ></KSTKSelect>
              </StyledUncontrolledCollapse>
              <h5 id="headersSection">Headers</h5>
              <StyledUncontrolledCollapse toggler="#headersSection">
                <AgGridReact
                  columnDefs={this.customHeadersColumns}
                  rowData={this.state.customHeadersRows?.sort((a, b) => (a.name < b.name ? -1 : 1))}
                  onCellValueChanged={this.onHeadersGridCellValueChanged}
                  // onGridReady={this.onGridReady}
                />
              </StyledUncontrolledCollapse>
              <h5 id="modelationSection">Modelations</h5>
              <StyledUncontrolledCollapse toggler="#modelationSection">
                <AgGridReact
                  columnDefs={this.customModelationsColumns}
                  rowData={this.state.customModelationsRows}
                  onCellValueChanged={this.onModelationsDataTypesGridCellValueChanged}
                  // onGridReady={this.onGridReady}
                />
              </StyledUncontrolledCollapse>
              <h5 id="dataTypesSection">Data types</h5>
              <StyledUncontrolledCollapse toggler="#dataTypesSection">
                <AgGridReact
                  columnDefs={this.customDataTypesColumns}
                  rowData={this.state.customDataTypesRows}
                  onCellValueChanged={this.onModelationsDataTypesGridCellValueChanged}
                  // onGridReady={this.onGridReady}
                />
              </StyledUncontrolledCollapse>
              <h5 id="customColumnsSection">Custom columns</h5>
              <StyledUncontrolledCollapse toggler="#customColumnsSection">
                {this.state.customColumns != null &&
                this.state.customColumns[this.state.selected.id] != null &&
                this.state.selected != null
                  ? this.state.customColumns[this.state.selected.id].map((fv, i) => (
                      <KSTKCustomColumnsValuesWrapper>
                        <KSTKCustomColumnsValues
                          name={fv.name}
                          customFunction={fv.customFunction}
                          onChange={(value) => this.handleCustomColumnsChange(i, value)}
                          key={"" + i}
                        />
                        <i
                          className="fa-solid fa-minus"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => this.removeCustomColumn(i)}
                        />
                      </KSTKCustomColumnsValuesWrapper>
                    ))
                  : null}
                <i className="fa-solid fa-plus" style={{ padding: "5px" }} onClick={() => this.addCustomColumn()} />
              </StyledUncontrolledCollapse>
            </SourceTransformationPanel>
          </GridsWrapper>
        )}
      </Wrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KSTKReportSourcesEditor);
