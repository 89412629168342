export const ColumnTypes = {
  COLUMN: "COLUMN",
  MEASURE: "MEASURE",
  VARIABLE: "VARIABLE",
};

export function getColumnsFromDatasource(datasource) {
  const ds =
    datasource && datasource.resources
      ? datasource.resources
          .map((r) => {
            return r.columns.map((cols) => `${r.name}.${cols.name}`);
          })
          .reduce((p, c) => {
            return p.concat(c);
          }, [])
          .map((c) => {
            return {
              type: ColumnTypes.COLUMN,
              value: c,
              label: c,
            };
          })
      : [];

  const mea =
    datasource && datasource.measures
      ? datasource.measures.map((m) => {
          return {
            type: ColumnTypes.MEASURE,
            value: m,
            label: m.name,
          };
        })
      : [];

  const variables =
    datasource && datasource.variables
      ? datasource.variables.map((v) => {
          return {
            type: ColumnTypes.VARIABLE,
            value: v,
            label: v.variableName,
          };
        })
      : [];

  return ds.concat(mea).concat(variables);
}
