import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
  text-align: center;

  &.small {
    border: 0px;
    margin: 20px 0px;
  }
`;

const Content = styled.div`
  display: grid;
  justify-items: center;
  gap: 20px;

  & i {
    font-size: 60px;
  }

  & span {
    font-size: 20px;
  }

  &.small {
    gap: 5px;
    i {
      font-size: 40px;
    }
    span {
      font-size: 15px;
    }
  }
`;

const KSTKEmptyContent = (props) => {
  return (
    <Wrapper className={props.small === true ? "small" : ""}>
      <Content className={props.small === true ? "small" : ""}>
        <i className={props.icon ? `fas fa-${props.icon}` : "fas fa-bullseye"} />
        <span>{props.message ? props.message : "no content to show..."}</span>
      </Content>
    </Wrapper>
  );
};

export default KSTKEmptyContent;
