import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--dark);
  color: var(--white);
  font-size: 12px;
`;

export function KSTKSidePanel(props) {
  return <Wrapper>{props.content}</Wrapper>;
}
