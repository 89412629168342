import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Label, Input, Card, CardText, CardImg, Alert } from "reactstrap";
import styled from "styled-components";

import { login, fetchAuthentication, persistAuthentication } from "../services/Client";

const LoginWrapper = styled.form`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LoginContent = styled(Card)`
  width: 300px;
`;

const LoginFieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 33% 66%;
  grid-template-rows: 50% 50%;
  align-items: center;
  align-content: space-between;
  height: 100px;
`;

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.authenticate = this.authenticate.bind(this);

    this.state = {
      isLoggedIn: this.props.isLoggedIn,
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  authenticate(e) {
    e.preventDefault();

    login(this.state.email, this.state.password)
      .then((loggedUserData) => {
        this.setState(() => ({
          isLoggedIn: true,
          errorMessage: "",
        }));

        persistAuthentication(loggedUserData);

        this.props.authenticate(loggedUserData);
        console.log("just logged in... redirecting 🚀");
        this.props.history.push("/reports/list");
      })
      .catch((error) => {
        this.setState({
          errorMessage: "😨 Oops, something went wrong...",
        });
      });
  }

  reformAuthentication = () => {
    const authData = fetchAuthentication();
    if (authData) {
      this.setState(() => ({
        isLoggedIn: true,
      }));

      this.props.authenticate(authData);
      console.log("already logged in... redirecting 🚀");
      this.props.history.push("/reports/list");

      return true;
    }

    return false;
  };

  isLoggedIn = () => {
    if (fetchAuthentication() == null) {
      return false;
    }
    return this.state.isLoggedIn || this.reformAuthentication();
  };

  render() {
    if (this.isLoggedIn()) {
      return <Redirect to="/reports/list"></Redirect>;
    }
    return (
      <LoginWrapper onSubmit={this.authenticate}>
        <LoginContent body>
          <CardImg top width="100%" src="/assets/logo.svg" alt="Card image cap" />
          <CardText>
            <LoginFieldsGroup>
              <Label htmlFor="kstkEmail">Email</Label>
              <Input
                type="email"
                name="email"
                id="kstkEmail"
                placeholder=""
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                getRef={(input) => (input.autofocus = "true")}
              />

              <Label htmlFor="kstkPassword">Password</Label>
              <Input
                type="password"
                name="password"
                id="kstkPassword"
                placeholder=""
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </LoginFieldsGroup>
            {this.state.errorMessage.length > 0 ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}
          </CardText>
          <Button type="submit">Login</Button>
        </LoginContent>
      </LoginWrapper>
      // <div>
      //     <p>Login Screen</p>
      //     <Button onClick={this.authenticate}>Login</Button>
      // </div>
    );
  }
}
