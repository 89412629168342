import React, { useContext, useState, useEffect } from "react";
import { ReportContext } from "./ReportContext";
import { KSTKSelect } from "../KSTKSelect";

const MultiValue = (props) => {
  let selectedOptionsString = [];
  props.selectProps.value.forEach((el) => {
    selectedOptionsString.push(el.value);
  });
  if (props.children === props.selectProps.value[0].label) {
    if (selectedOptionsString.length > 1) {
      return <div>{"(" + props.selectProps.value.length + ")"}</div>;
    } else {
      return <div>{selectedOptionsString.toString()}</div>;
    }
  } else {
    return "";
  }
};

export function KSTKSelectionDropdown(props) {
  const {
    report,
    selection,
    filter,
    selectedSource,
    selectedPage,
    datasource,
    setChartValue,
    kpiWithPreselectedValues,
    setKPIWithPreselectedValues,
    removeKPIWithPreselectedValues,
  } = useContext(ReportContext);

  useEffect(() => {
    if (
      kpiWithPreselectedValues.indexOf(props.index + selectedPage) == -1 &&
      props.metadata &&
      props.data &&
      props.data.originalLabels &&
      props.data.originalLabels.length > 0 &&
      props.metadata.preselectedMode != null &&
      props.metadata.preselectedMode != ""
    ) {
      let preselectedValue;
      if (props.metadata.preselectedMode == "firstAlphabetical") {
        props.data.originalLabels.forEach((el) => {
          if (preselectedValue == null) {
            preselectedValue = el;
          } else {
            if (preselectedValue.localeCompare(el.toString(), "pt") == 1) {
              preselectedValue = el;
            }
          }
        });
      } else if (props.metadata.preselectedMode == "highestValue") {
        props.data.originalLabels.forEach((el) => {
          if (!isNaN(Number(el))) {
            if (preselectedValue == null) {
              preselectedValue = el;
            } else {
              if (Number(preselectedValue) < Number(el)) {
                preselectedValue = el;
              }
            }
          }
        });
      }
      if (preselectedValue != null) {
        setKPIWithPreselectedValues(props.index + selectedPage);
        setChartValue(props.index, [{ label: preselectedValue, value: preselectedValue }]);
      }
    } else if (
      kpiWithPreselectedValues.indexOf(props.index + selectedPage) != -1 &&
      (props.metadata.preselectedMode == null || props.metadata.preselectedMode == "")
    ) {
      removeKPIWithPreselectedValues(props.index + selectedPage);
      setChartValue(props.index, []);
    }
  }, [props.data]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length == undefined) {
      setChartValue(props.index, [selectedOptions]);
    } else {
      setChartValue(props.index, selectedOptions);
    }
  };

  const stopEventPropragation = (e) => {
    e.stopPropagation();
  };

  return (
    <KSTKSelect
      index={props.index}
      components={{ MultiValue }}
      value={props.metadata && props.metadata.values ? props.metadata.values : []}
      isMulti={
        props.metadata?.axis && props.metadata.axis.length > 0 && props.metadata.axis[0].variableName != null
          ? false
          : true
      }
      isSearchable="true"
      isClearable="true"
      onChange={handleChange}
      options={
        props.data && props.data.originalLabels && props.data.originalLabels.length
          ? props.data.originalLabels.map((d, i) => {
              return {
                value:
                  props.data.originalLabels && props.data.originalLabels.length
                    ? props.data.originalLabels[i] == null || props.data.originalLabels[i] == ""
                      ? ""
                      : props.data.originalLabels[i]
                    : d,
                label:
                  props.data.originalLabels && props.data.originalLabels.length
                    ? props.data.originalLabels[i] == null || props.data.originalLabels[i] == ""
                      ? "(vazio)"
                      : props.data.originalLabels[i]
                    : d,
              };
            })
          : []
      }
      onFocus={stopEventPropragation}
    />
  );
}
