import {
  FETCHING_REPORTS,
  SEARCH_REPORTS,
  SELECT_REPORTS,
  CREATE_REPORT,
  DELETE_REPORT,
} from "../actions/ReportActions";

export default (
  state = {
    reports: [],
    filteredReports: [],
    selectedReport: undefined,
  },
  action
) => {
  switch (action.type) {
    case FETCHING_REPORTS:
      return {
        ...state,
        reports: action.payload,
        filteredReports: action.payload,
      };
    case SEARCH_REPORTS:
      return {
        ...state,
        filteredReports: state.reports.filter((r) => {
          return (
            r.name.toLowerCase().indexOf(action.payload) > -1 ||
            r.description?.toLowerCase().indexOf(action.payload) > -1 ||
            r.tags?.toLowerCase().indexOf(action.payload) > -1
          );
        }),
      };
    case SELECT_REPORTS:
      return {
        ...state,
        selectedReport: state.reports.find((rep) => rep.id === action.payload),
      };
    case CREATE_REPORT:
      return {
        ...state,
        reports: state.reports.concat(action.payload),
      };
    case DELETE_REPORT:
      return {
        ...state,
        reports: state.reports.filter((r) => {
          return r.id !== action.payload;
        }),
      };
    default:
      return state;
  }
};
