import { generateService, BASE_URL } from "./Util";
import { fetchAuthentication } from "./Client";
import { handleErrors } from "./Util";

const service = generateService("Clients");

export const getClients = service.get;

export const getClientFilterByInfo = () => {
  const authenticationInfo = fetchAuthentication().value;
  return { id: authenticationInfo.userId, organization: authenticationInfo.user.organization };
};

export const getIsAdmin = () => {
  const authenticationInfo = fetchAuthentication().value;
  return authenticationInfo.user != null &&
    authenticationInfo.user.profile != null &&
    authenticationInfo.user.profile == "admin"
    ? true
    : false;
};

export const getClientReportPermissions = (repId) => {
  return fetch(`${BASE_URL}/Clients/getClientReportPermissions/${repId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  }).then(handleErrors);
};
