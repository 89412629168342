export const CREATE_DATASOURCE = "CREATE_DATASOURCE";
export const FETCHING_DATASOURCES = "FETCHING_DATASOURCES";
export const SEARCH_DATASOURCES = "SEARCH_DATASOURCES";
export const SELECT_DATASOURCE = "SELECT_DATASOURCE";
export const UPDATE_DATASOURCE = "UPDATE_DATASOURCE";
export const DELETE_DATASOURCE = "DELETE_DATASOURCE";
export const UPSERT_SOURCE = "UPSERT_SOURCE";
export const REMOVE_SOURCE = "REMOVE_SOURCE";
export const SET_SOURCES = "SET_SOURCES";
export const UPSERT_RELATION = "UPSERT_RELATION";
export const REMOVE_RELATION = "REMOVE_RELATION";
export const UPDATE_METADATA = "UPDATE_METADATA";
export const UPSERT_VARIABLE = "UPSERT_VARIABLE";
export const REMOVE_VARIABLE = "REMOVE_VARIABLE";

export const createDataSource = (datasource) => (dispatch) => {
  dispatch({
    type: CREATE_DATASOURCE,
    payload: datasource,
  });
};

export const fetchDataSources = (data) => (dispatch) => {
  dispatch({
    type: FETCHING_DATASOURCES,
    payload: data,
  });
};

export const searchDataSources = (search) => (dispatch) => {
  dispatch({
    type: SEARCH_DATASOURCES,
    payload: search,
  });
};

export const selectDataSource = (id) => (dispatch) => {
  dispatch({
    type: SELECT_DATASOURCE,
    payload: id,
  });
};

export const updateDatasource = (datasource) => (dispatch) => {
  dispatch({
    type: UPDATE_DATASOURCE,
    payload: datasource,
  });
};

export const deleteDataSource = (id) => (dispatch) => {
  dispatch({
    type: DELETE_DATASOURCE,
    payload: id,
  });
};

export const upsertSource = (source, datasourceId) => (dispatch) => {
  dispatch({
    type: UPSERT_SOURCE,
    payload: {
      source: source,
      datasourceId: datasourceId,
    },
  });
};

export const removeSource = (source, datasourceId) => (dispatch) => {
  dispatch({
    type: REMOVE_SOURCE,
    payload: {
      source: source,
      datasourceId: datasourceId,
    },
  });
};

export const setSources = (sources, sourceType, datasourceId) => (dispatch) => {
  dispatch({
    type: SET_SOURCES,
    payload: {
      sources: sources,
      sourceType: sourceType,
      datasourceId: datasourceId,
    },
  });
};

export const upsertRelation = (relation, datasourceId) => (dispatch) => {
  dispatch({
    type: UPSERT_RELATION,
    payload: {
      relation: relation,
      datasourceId: datasourceId,
    },
  });
};

export const removeRelation = (relation, datasourceId) => (dispatch) => {
  dispatch({
    type: REMOVE_RELATION,
    payload: {
      relation: relation,
      datasourceId: datasourceId,
    },
  });
};

export const removeVariable = (variable, datasourceId) => (dispatch) => {
  dispatch({
    type: REMOVE_VARIABLE,
    payload: {
      variable: variable,
      datasourceId: datasourceId,
    },
  });
};
export const updateMetadata = (metadata, datasourceId) => (dispatch) => {
  dispatch({
    type: UPDATE_METADATA,
    payload: {
      metadata: metadata,
      datasourceId: datasourceId,
    },
  });
};

export const upsertVariable = (variable, datasourceId) => (dispatch) => {
  dispatch({
    type: UPSERT_VARIABLE,
    payload: {
      variable: variable,
      datasourceId: datasourceId,
    },
  });
};
