export const getTableData = (
  selectedSource,
  datasource,
  metadata,
  filter,
  autoUpdateTimerFlag,
  layout,
  serverside,
  upperScopeFunctions
) => {
  const {
    buildQueryParams,
    publishNotification,
    getChartData,
    KSTKChartToChartJS,
    getModulesData,
    getEnvolvedTables,
    processModuleData,
    setExportData,
  } = upperScopeFunctions;
  const { skip, limit } = serverside;

  try {
    if (!selectedSource || !datasource) {
      return;
    }

    let sourceId = selectedSource.id;

    if (filter == "ORG" || datasource.sourceType == "mongo") {
      sourceId =
        selectedSource.organization && selectedSource.organization != "organization"
          ? selectedSource.organization
          : selectedSource.id;
    } else if (filter == "USER") {
      sourceId = selectedSource.organization;
    }

    if (metadata && metadata.values) {
      if (datasource.sourceType == "postgres") {
        let queryParams = buildQueryParams();
        if (queryParams != null && queryParams != undefined) {
          //queryParamsCache = JSON.stringify(queryParams);
          return getChartData(sourceId, queryParams, { skip: skip, limit: limit })
            .then((data) => {
              const variablesInLayout = layout
                .filter(
                  (l) =>
                    l.metadata?.axis &&
                    l.metadata.axis?.length &&
                    l.metadata.axis[0].variableName &&
                    l.metadata.values &&
                    l.metadata.values.length &&
                    l.metadata.values[0].value != null
                )
                .map((l) => {
                  return {
                    ...l.metadata.axis[0],
                    value: l.metadata.values.length ? l.metadata.values[0].value : null,
                  };
                });
              let variablesValues = {};
              variablesInLayout.forEach((v) => {
                variablesValues[v.variableName] = v.value;
              });

              const variablesInValues =
                metadata.values != null
                  ? metadata?.values
                      .filter((v) => v.field?.variableName != null)
                      .filter((v) => variablesInLayout.find((l) => l.variableName == v.field.variableName))
                      .map((v) => v.field)
                  : [];

              data.data.forEach((d) => {
                variablesInValues.forEach((v) => {
                  d[v.variableName.replace("$$ ", "")] = variablesValues[v.variableName];
                });
              });

              return data;

              //setData(JSON.parse(JSON.stringify(obj)));
              //setFilteredData(JSON.parse(JSON.stringify(obj)));
              //setExportData(obj.exportData);
            })
            .catch((err) => {
              return {};
              publishNotification({
                type: "alert",
                message: `Error (Table -> ${metadata.title}): ${err?.error?.message || err}`,
              });
            });
        }
      } else if (datasource.sourceType == "mongo") {
        let queryParams = buildQueryParams();
        //queryParamsCache = JSON.stringify(queryParams);
        return getModulesData(
          datasource.module,
          sourceId,
          null,
          getEnvolvedTables(true),
          selectedSource,
          filter,
          autoUpdateTimerFlag
        )
          .then((data) => {
            let processedData = processModuleData(data, queryParams);
            let obj = KSTKChartToChartJS(processedData, metadata, layout);
            //setData(JSON.parse(JSON.stringify(obj)));
            //setFilteredData(JSON.parse(JSON.stringify(obj)));
            setExportData(obj.exportData);
            return {
              data: processedData,
              count: processedData.length,
            };
          })
          .catch(function (err) {
            setExportData([]);
            publishNotification({
              type: "alert",
              message: `Error  (Table -> ${metadata.title}): ${err?.error?.message || err}`,
            });
            return {
              data: [],
              count: 0,
            };
          });
      }
    } else {
      //TODO
      //clearData();
    }
  } catch (err) {
    return {
      data: [],
      count: 0,
    };
    publishNotification({
      type: "alert",
      message: `Error (getData -> ${err?.error?.message || err}`,
    });
  }
};

export const aggregators = [
  { value: "sum", label: "Som." },
  { value: "avg", label: "Média" },
  { value: "count", label: "Total" },
  { value: "count_dist", label: "Total (únicos)" },
  { value: "max", label: "Max" },
  { value: "min", label: "Min" },
  { value: "distinct", label: "Únicos" },
];

export const getTableDataExportUrl = (selectedSource, datasource, metadata, filter, layout, upperScopeFunctions) => {
  const { buildQueryParams, publishNotification, exportTableData } = upperScopeFunctions;

  try {
    if (!selectedSource || !datasource) {
      return;
    }

    let sourceId = selectedSource.id;

    if (filter == "ORG" || datasource.sourceType == "mongo") {
      sourceId =
        selectedSource.organization && selectedSource.organization != "organization"
          ? selectedSource.organization
          : selectedSource.id;
    } else if (filter == "USER") {
      sourceId = selectedSource.organization;
    }

    if (metadata && metadata.values) {
      if (datasource.sourceType == "postgres") {
        let queryParams = buildQueryParams();
        if (queryParams != null && queryParams != undefined) {
          //queryParamsCache = JSON.stringify(queryParams);
          return exportTableData(sourceId, queryParams, datasource)
            .then((data) => {
              const variablesInLayout = layout
                .filter(
                  (l) =>
                    l.metadata.axis &&
                    l.metadata.axis?.length &&
                    l.metadata.axis[0].variableName &&
                    l.metadata.values &&
                    l.metadata.values.length &&
                    l.metadata.values[0].value != null
                )
                .map((l) => {
                  return {
                    ...l.metadata.axis[0],
                    value: l.metadata.values.length ? l.metadata.values[0].value : null,
                  };
                });
              let variablesValues = {};
              variablesInLayout.forEach((v) => {
                variablesValues[v.variableName] = v.value;
              });

              const variablesInValues =
                metadata.values != null
                  ? metadata.values
                      .filter((v) => v.field?.variableName != null)
                      .filter((v) => variablesInLayout.find((l) => l.variableName == v.field.variableName))
                      .map((v) => v.field)
                  : [];

              /* data.data.forEach((d) => {
                variablesInValues.forEach((v) => {
                  d[v.variableName.replace("$$ ", "")] = variablesValues[v.variableName];
                });
              }); */
              return data;
            })
            .catch((err) => {
              publishNotification({
                type: "alert",
                message: `Error (Export Table -> ${metadata.title}): ${err?.error?.message || err}`,
              });
            });
        }
      } else {
        // Not implemented has this is done in the KSTKKPI.js for MongoDB datasources
      }
    } else {
      //TODO?
      //clearData();
    }
  } catch (err) {
    publishNotification({
      type: "alert",
      message: `Error (exportData -> ${err?.error?.message || err}`,
    });
  }
};
