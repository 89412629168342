import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

export default class KSTKNavbar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.goToReportsList = this.goToReportsList.bind(this);

    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  getUserName() {
    if (this.props.loggedUserData) {
      return `${this.props.loggedUserData.user.name} ${this.props.loggedUserData.user.surname}`;
    }
  }

  goToReportsList() {
    this.props.history.push("/reports/list");
  }

  render() {
    return (
      <Navbar color="light" light expand="md" className="navbar-dark bg-dark">
        <NavbarBrand href="/">
          <img width="20" src="/assets/logo_small.jpg"></img> KSTK Report Builder
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/reports/list">
                Reports <Badge color="secondary">{this.props.reportCount === 0 ? "?" : this.props.reportCount}</Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/datasources/list">
                DataSources{" "}
                <Badge color="secondary">{this.props.datasourceCount === 0 ? "?" : this.props.datasourceCount}</Badge>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret color="black">
                {this.getUserName()}
              </DropdownToggle>
              <DropdownMenu end dark>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <div onClick={this.props.logout}>Logout</div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
