import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Input } from "reactstrap";
import Map, { Source, Layer, CircleLayer, SymbolLayer, Popup, Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "../../scss/mapbox-gl.css";
import styled from "styled-components";
import { ReportContext } from "./ReportContext";
import { maps } from "../../maps/maps";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export function KSTKMap(props) {
  const { setChartValue, layout } = useContext(ReportContext);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [mapData, setMapData] = useState(null);
  const [clickedPoints, setClickedPoints] = useState([]);
  const [filteredByDropdowns, setFilteredByDropdowns] = useState(false);
  const [pointField, setPointField] = useState("AE / ENA");

  const mapRef = useRef();

  const emptyMapStyle = {
    version: 8,
    name: "Empty",
    metadata: {
      "mapbox:autocomposite": true,
    },
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    sources: {},
    layers: [],
  };

  useEffect(() => {
    if (props?.metadata?.values && props.metadata.values[0]) {
      setClickedPoints(props.metadata.values.map((el) => el.value));
    }
  }, []);

  const circleLayerStyle: CircleLayer = {
    id: "point",
    type: "circle",
    paint: {
      "circle-radius": 10,
      "circle-color": [
        "case",
        ["in", ["get", pointField], ["literal", clickedPoints]],
        "black",
        ["case", ["boolean", ["feature-state", "hover"], false], "rgb(51,51,51)", "white"],
      ],
    },
  };

  const symbolLayerStyle: SymbolLayer = {
    id: "point-text",
    type: "symbol",
    filter: ["has", pointField],
    layout: {
      "text-field": ["get", pointField],
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
      "text-allow-overlap": true,
      "text-anchor": "left",
      "text-offset": [1, 0],
      "text-max-width": 15,
    },
    paint: {
      "text-halo-color": "#fff",
      "text-halo-width": 1,
    },
  };

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;

    const hoveredFeature = features && features[0];
    // prettier-ignore
    setHoverInfo(hoveredFeature && {feature: hoveredFeature, lng: event.lngLat.lng, lat: event.lngLat.lat});
    if (features && features[0]) {
      mapRef.current.setFeatureState({ source: hoveredFeature.source, id: hoveredFeature.id }, { hover: true });
    }
  }, []);

  const onLeave = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;

    if (features && features[0]) {
      mapRef.current.setFeatureState({ source: features[0].source, id: features[0].id }, { hover: false });
    }
  }, []);

  const handleMapClick = (selectedPoint) => {
    if (selectedPoint && selectedPoint.length == undefined) {
      setChartValue(props.index, [selectedPoint]);
    } else {
      setChartValue(props.index, selectedPoint);
    }
  };

  const onClick = useCallback(
    (event) => {
      const {
        features,
        point: { x, y },
      } = event;

      if (!filteredByDropdowns) {
        if (features != null && features.length == 0) {
          // Click outside of the points
          setClickedPoints([]);
          handleMapClick([]);
        } else if (features && features[0]) {
          if (clickedPoints.indexOf(features[0].properties[pointField]) != -1) {
            // Click on the point that was previously clicked
            setClickedPoints([]);
            handleMapClick([]);
          } else {
            // Click on other point
            if (clickedPoints.length && clickedPoints.indexOf(features[0].properties[pointField]) == -1) {
              let newClickedPoints = JSON.parse(JSON.stringify(clickedPoints));
              newClickedPoints.push(features[0].properties[pointField]);
              setClickedPoints(newClickedPoints);
              handleMapClick(
                newClickedPoints.map((el) => {
                  return { label: el, value: el };
                })
              );
            } else {
              setClickedPoints([features[0].properties[pointField]]);
              handleMapClick([
                { label: features[0].properties[pointField], value: features[0].properties[pointField] },
              ]);
            }
          }
        }
      }
    },
    [clickedPoints]
  );

  const pins = useMemo(
    () =>
      maps.vfx.features.map((location, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={location.geometry.coordinates[0]}
          latitude={location.geometry.coordinates[1]}
          anchor="bottom"
          /* onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(location);
          }} */
        ></Marker>
      )),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      mapRef?.current?.resize();
    }, 2000);
  }, []);

  useEffect(() => {
    // Set map language based on the user browser agent
    const language = new MapboxLanguage();
    mapRef.current?.addControl(language);

    // First map size setup
    if (mapRef.current?.resize) {
      mapRef.current.resize();
    }

    // Set point field based on chosen map
    if (props.metadata?.map && props.metadata.map != "" && maps[props.metadata.map].pointField) {
      setPointField(maps[props.metadata.map].pointField);
    }
  }, [layout, props.metadata]);

  useEffect(() => {
    if (props.data) {
      let newMapData = {};
      props.data.tooltips?.forEach((tooltipInfo) => {
        for (let w = 0; w < tooltipInfo.originalLabels.length; w++) {
          let cluster = tooltipInfo.originalLabels[w];
          if (newMapData[cluster] == null) {
            newMapData[cluster] = [];
          }
          let parsedValue = tooltipInfo.label + ": " + tooltipInfo.data[w];
          newMapData[cluster].push(parsedValue);
        }
      });
      /* props.data.exportData?.forEach((dataEl) => {
        newMapData[dataEl[pointField]] = [];
        for (const key in dataEl) {
          if (Object.hasOwnProperty.call(dataEl, key) && key != pointField) {
            const element = dataEl[key];
            let parsedValue = key + ": " + element;
            newMapData[dataEl[pointField]].push(parsedValue);
          }
        }
      }); */
      if (Object.keys(newMapData).length > 0) {
        setMapData(newMapData);
      }

      // Update clicked points from dropdown values

      let dropdownSelectedValues = [];
      if (layout?.length > 0) {
        layout.forEach((layoutEl) => {
          if (
            layoutEl.i != props.index &&
            layoutEl.kpiType == "dropdown" &&
            layoutEl.metadata?.values != null &&
            layoutEl.metadata?.values?.length > 0
          ) {
            if (props.metadata?.map && props.metadata.map != "" && maps[props.metadata.map].dataField) {
              if (layoutEl.metadata?.axis && layoutEl.metadata?.axis == maps[props.metadata.map].dataField) {
                layoutEl.metadata.values.forEach((value) => {
                  if (dropdownSelectedValues.indexOf(value.label) == -1) {
                    dropdownSelectedValues.push(value.label);
                  }
                });
              }
            } else {
              layoutEl.metadata.values.forEach((value) => {
                if (dropdownSelectedValues.indexOf(value.label) == -1) {
                  dropdownSelectedValues.push(value.label);
                }
              });
            }
          }
        });
      }
      if (dropdownSelectedValues.length) {
        setClickedPoints(dropdownSelectedValues);
        handleMapClick([]);
        setFilteredByDropdowns(true);
      } else {
        if (filteredByDropdowns) {
          setClickedPoints([]);
          handleMapClick([]);
        }
        setFilteredByDropdowns(false);
      }
    }
  }, [props.data]);

  const changeMapLanguage = (map) => {};

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_MAPBOX_KEY
          : "pk.eyJ1IjoicGVkcm92ZWlnYWtzdGsiLCJhIjoiY2x4ZXQxNThkMGljNTJrc2h3Nnl0cmR4MCJ9.r1_7QMga5udZcrPZD-V4UQ"
      }
      longitude={props.metadata?.map && props.metadata.map != "" ? maps[props.metadata.map].longitude : -9.03}
      latitude={props.metadata?.map && props.metadata.map != "" ? maps[props.metadata.map].latitude : 38.932}
      zoom={
        props.metadata?.mapZoom
          ? props.metadata.mapZoom
          : props.metadata?.map && props.metadata.map != ""
          ? maps[props.metadata.map].zoom
          : 10
      }
      interactiveLayerIds={["point", "point-text"]}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
      mapStyle="mapbox://styles/mapbox/light-v10"
      onStyleLoad={changeMapLanguage}
    >
      <Source
        id="map-source"
        type="raster"
        url={
          props.metadata?.map && props.metadata.map != ""
            ? maps[props.metadata.map].mapboxSource
            : "mapbox://pedroveigakstk.46xd7m1j"
        }
      >
        <Layer id="overlay" source="map-source" type="raster" />
      </Source>
      <Source
        id="data"
        type="geojson"
        generateId={true}
        data={props.metadata?.map && props.metadata.map != "" ? maps[props.metadata.map] : maps.vfx}
      >
        <Layer {...circleLayerStyle} />
        <Layer {...symbolLayerStyle} />
      </Source>

      {/* {pins} */}

      {hoverInfo && (
        <Popup longitude={hoverInfo.lng} latitude={hoverInfo.lat} closeButton={false}>
          <div>
            <b>{hoverInfo.feature.properties[pointField]}</b>
            {mapData != null &&
              mapData[hoverInfo.feature.properties[pointField]]?.map((mapDataEl) => {
                return (
                  <div>
                    <span>{mapDataEl}</span>
                  </div>
                );
              })}
          </div>
        </Popup>
      )}
    </Map>
  );
}
