import React from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import styled from "styled-components";
import { UncontrolledTooltip } from "reactstrap";

const StyleSelect = styled(Select)`
  color: var(--dark);

  & > div {
    height: calc(1.5em + 0.75rem + 2px);
    min-height: calc(1.5em + 0.75rem + 2px) !important;

    & > div {
      height: 100%;
    }
  }
`;

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ color: "#3a62ac" }}>
        <i className="fas fa-magnifying-glass" />
      </span>
    </components.DropdownIndicator>
  );
};

let indexDivIdMap = {};

export function KSTKSelect(props) {
  let selectedOptionsString = [];

  // Only generate divId when the select is a KPI
  if (props.index) {
    if (indexDivIdMap[props.index] == null) {
      indexDivIdMap[props.index] = "id" + Math.random().toString(16).slice(2);
    }
    if (props.value && props.value.length) {
      props.value.forEach((el) => {
        selectedOptionsString.push(el.label);
      });
    } else if (props?.value?.label != null) {
      selectedOptionsString.push(props.value.label);
    }
  }

  return (
    <div id={indexDivIdMap[props.index]}>
      <StyleSelect
        {...props}
        components={props.dropdownIndicator == "search" ? { DropdownIndicator } : props.components}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={{ ...props.styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        placeholder={props.placeholder != null ? props.placeholder : "Selecione"}
      ></StyleSelect>
      {props.index && selectedOptionsString.length > 0 ? (
        <UncontrolledTooltip target={indexDivIdMap[props.index]}>
          {selectedOptionsString.toString().replace(/\,/gi, ", ")}
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
}
