import React, { Component, useRef } from "react";
import GridLayout from "react-grid-layout";
import styled from "styled-components";
import sizeMe from "react-sizeme";
import { v1 } from "uuid";
import produce from "immer";
import classnames from "classnames";
import { Nav, NavItem, NavLink, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { KSTKKPI } from "./reporting/KSTKKPI";
import { ReportContext } from "./reporting/ReportContext";
import { KSTKVisualsSidePanel } from "./KSTKVisualsSidePanel";
// import { KSTKFieldsSidePanel } from "./KSTKFieldsSidePanel";
import { KSTKSplitView } from "./KSTKSplitView";
import { KSTKSelect } from "./KSTKSelect";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import { publishNotification } from "../actions/NotificationActions";
import { Chart as ChartJS, defaults } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { connect } from "react-redux";

import { getIsAdmin } from "../services/clients.service";
import { logReportPageView } from "../services/report.service";

const mapDispatchToProps = (dispatch) => ({
  publishNotification: (metadata) => dispatch(publishNotification(metadata)),
});

const mapStateToProps = (state) => ({
  ...state,
});

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  user-select: none;
  /* justify-content: center;
  align-content: center;
  height: 100%;
  background-color: lightgray;
  overflow: auto;

  position: relative;

  & > * {
    background-color: white;
  } */
`;

const NavWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  &.editMode {
    height: 50px;
    padding-right: 8px;
    margin-bottom: 8px;
    align-items: baseline;
  }

  &.viewMode {
    height: 40px;
    padding: 5px 0px;
    border-top: 1px solid #e8e8e8;
    box-shadow: 0px 10px 43px 9px rgb(83 87 103 / 23%);
  }
`;

const PageSelect = styled(KSTKSelect)`
  width: 300px;
`;

const StyledNav = styled(Nav)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
`;

const StyledNavItem = styled(NavItem)`
  a {
    &.active {
      font-weight: 700;
    }
  }
`;

const GridLayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const VisualsWrapper = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
`;

const ReportPageNavLink = styled(NavLink)`
  width: max-content;
  cursor: pointer;
`;

const Spacer = styled.div`
  display: flex;
  flex-grow: 1;
`;

// const VisualizationPane = styled.div`
//   /* position: absolute;
//   right: 100px;
//   top: 0; */
//   height: 100%;
//   width: 100%;
//   background-color: gray;
// `;

// const FieldsPane = styled.div`
//   /* position: absolute;
//   right: 0;
//   top: 0; */
//   height: 100%;
//   width: 200px;
//   background-color: black;
// `;

type ChartLayout = {
  i: string,
  x: number,
  y: number,
  w: number,
  h: number,
  kpiType: string,
  mode?: string,
  metadata?: ChartMetadata,
};
type ReportLayout = Array<ChartLayout>;

type ReportPage = {
  id: string,
  name: string,
  filters: Array<object>,
  layout: ReportLayout,
};

class KSTKReportEditor extends Component {
  constructor(props) {
    super(props);
    this.generateLayoutItems = this.generateLayoutItems.bind(this);
    // this.serialize = this.serialize.bind(this);
    // this.filter = this.setState.bind(this);

    // let layout: ReportLayout = [
    //   {
    //     i: "a",
    //     x: 0,
    //     y: 0,
    //     w: 5,
    //     h: 4,
    //     kpiType: "bar",
    //     metadata: {
    //       axis: ["avaliacoes.ano_lectivo"],
    //       legend: ["avaliacoes.ano_lectivo"],
    //       values: [
    //         {
    //           field: "avaliacoes.Aluno",
    //           aggregator: "count"
    //         }
    //       ]
    //     }
    //   },
    //   { i: "b", x: 1, y: 0, w: 2, h: 2, kpiType: "line" },
    //   { i: "c", x: 4, y: 3, w: 4, h: 1, kpiType: "label" },
    //   { i: "d", x: 0, y: 5, w: 4, h: 1, kpiType: "dropdown" }
    // ];
    let self = this;
    this.createPage = this.createPage.bind(this);
    this.addPage = this.addPage.bind(this);
    this.removePage = this.removePage.bind(this);
    this.duplicatePage = this.duplicatePage.bind(this);
    this.setSelectedPage = this.setSelectedPage.bind(this);
    this.filter = this.filter.bind(this);
    this.visualPanelIconClick = this.visualPanelIconClick.bind(this);
    this.kpiClick = this.kpiClick.bind(this);
    this.duplicateKPI = this.duplicateKPI.bind(this);
    this.removeKPI = this.removeKPI.bind(this);
    this.setSelectedChartMetadata = this.setSelectedChartMetadata.bind(this);
    this.setChartValue = this.setChartValue.bind(this);
    this.setPages = this.setPages.bind(this);
    this.setSelectedPageMetadata = this.setSelectedPageMetadata.bind(this);
    this.createPagePDF = this.createPagePDF.bind(this);
    this.createReportPDF = this.createReportPDF.bind(this);
    this.generateReportPDF = this.generateReportPDF.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.setKPIWithPreselectedValues = this.setKPIWithPreselectedValues.bind(this);
    this.removeKPIWithPreselectedValues = this.removeKPIWithPreselectedValues.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleResizeStart = this.handleResizeStart.bind(this);
    this.handleResizeStop = this.handleResizeStop.bind(this);
    this.removeOverflowKPIs = this.removeOverflowKPIs.bind(this);

    // Setup report pages, if they don't exist. Check if it is a meta report
    let pages: ReportPage = [];
    if (this.props.metaReportPages != null && this.props.metaReportPages.length) {
      pages = this.props.metaReportPages;
    } else {
      pages = this.props.report[0].pages || [this.createPage()];
    }

    // Setup first layout
    let layout: ReportLayout = pages[0].layout;

    this.state = {
      report: this.props.report[0],
      layout: layout,
      pages: pages,
      selectedPage: pages[0].id,
      setPages: this.setPages,
      setSelectedPageMetadata: this.setSelectedPageMetadata,
      selection: null,
      selectedChartArea: null,
      selectedChartAreaIndex: null,
      filter: this.filter,
      selectedChart: null,
      visualPanelIconClick: this.visualPanelIconClick,
      kpiClick: this.kpiClick,
      duplicateKPI: this.duplicateKPI,
      removeKPI: this.removeKPI,
      datasource: this.props.datasource,
      colorPalette: this.props.colorPalette,
      selectedSource: this.props.selected,
      selectedChartMetadata: null,
      setSelectedChartMetadata: this.setSelectedChartMetadata,
      setChartValue: this.setChartValue,
      mode: this.props.mode,
      dataMode: this.props.dataMode,
      publishNotification: this.props.publishNotification,
      originalWidth: window.innerWidth,
      originalHeight: window.innerHeight,
      dropdownOpen: false,
      kpiWithPreselectedValues: [],
      setKPIWithPreselectedValues: this.setKPIWithPreselectedValues,
      removeKPIWithPreselectedValues: this.removeKPIWithPreselectedValues,
      overflowVisuals: null,
      removeOverflowKPIs: this.removeOverflowKPIs,
    };

    if (!getIsAdmin()) {
      logReportPageView(pages[0].name);
    }

    this.state.layout = layout.map((l) => {
      if (this.props.mode == "view") {
        l = { ...l, mode: "view", static: true };
      } else {
        l = { ...l, mode: "edit" };
      }
      return l;
    });

    defaults.font.size = 11;
    ChartJS.register(ChartDataLabels);

    defaults.set("plugins.datalabels", {
      font: {
        weight: "bold",
      },
      color: "white",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.autoUpdateTimerFlag) !== JSON.stringify(this.props.autoUpdateTimerFlag)) {
      const newReport = [...this.props.report];
      this.setState({
        report: newReport,
      });
    }
    if (JSON.stringify(prevProps.mode) !== JSON.stringify(this.props.mode)) {
      this.setState({
        mode: this.props.mode,
      });
    }
    if (JSON.stringify(prevProps.colorPalette) !== JSON.stringify(this.props.colorPalette)) {
      this.setState({
        colorPalette: this.props.colorPalette,
      });
    }
    if (JSON.stringify(prevProps.datasource) !== JSON.stringify(this.props.datasource)) {
      this.setState({
        datasource: this.props.datasource,
      });
    }
    if (JSON.stringify(prevState.selectedSource) !== JSON.stringify(this.props.selected)) {
      this.setState({
        selectedSource: this.props.selected,
      });
    }
    if (JSON.stringify(prevState.layout) !== JSON.stringify(this.state.layout) || this.state.overflowVisuals == null) {
      if (this.state.layout != null && this.state.layout.length > 0) {
        let newOverflowVisuals = [];
        this.state.layout.forEach((layoutEl) => {
          if (layoutEl.x >= 20 || layoutEl.y >= 20) {
            newOverflowVisuals.push(layoutEl.i);
          }
        });
        this.setState({
          overflowVisuals: newOverflowVisuals,
        });
      }
    }
    if (JSON.stringify(prevProps.report) !== JSON.stringify(this.props.report)) {
      const newPages = this.props.report.length ? this.props.report[0].pages : [this.createPage()];
      this.setState({
        report: this.props.report,
        pages: newPages,
        selectedPage: newPages.length ? newPages[0].id : null,
        layout: newPages.length ? newPages[0].layout : [],
      });
    }
  }

  addPage() {
    const newPage = this.createPage();
    const newPageId = newPage.id;
    const newPages = this.state.pages.map((p) => produce(p, (tempPage) => tempPage)).concat(newPage);

    this.setState({
      pages: newPages,
      layout: newPage.layout.map((l) => {
        l.mode = "edit";
        return l;
      }),
      selectedPage: newPageId,
    });

    this.props.onChange(newPages);
  }

  createPage() {
    return {
      id: v1(),
      name: "New Page",
      filters: [],
      layout: [],
    };
  }

  duplicatePage(e, id) {
    let toDuplicatePage = this.state.pages.filter((p) => p.id == id);
    if (toDuplicatePage != null && Array.isArray(toDuplicatePage) && toDuplicatePage.length > 0) {
      toDuplicatePage = toDuplicatePage[0];
      toDuplicatePage = JSON.parse(JSON.stringify(toDuplicatePage));
      toDuplicatePage.id = v1();
      toDuplicatePage.name = "New Page";
      if (toDuplicatePage.layout?.length > 0) {
        toDuplicatePage.layout = toDuplicatePage.layout.map((k) => {
          k.i = v1();
          return k;
        });
      }
      const newPages = this.state.pages.concat([toDuplicatePage]);
      this.setState({
        pages: newPages,
      });
      this.props.onChange(newPages);
    }

    e.stopPropagation();
  }

  removePage(e, id) {
    const newPages = this.state.pages.map((p) => produce(p, (tempPage) => tempPage));
    const removedPage = newPages.splice(
      this.state.pages.findIndex((el) => el.id === id),
      1
    )[0];
    if (newPages.length > 0) {
      const isRemovingSelectedPage = removedPage.id === this.state.selectedPage;

      this.setState({
        selectedPage: isRemovingSelectedPage ? newPages[0].id : this.state.selectedPage,
        pages: newPages,
        layout: isRemovingSelectedPage ? newPages[0].layout : this.state.layout,
      });

      this.props.onChange(newPages);
    }

    e.stopPropagation();
  }

  setSelectedPage(id) {
    if (!getIsAdmin()) {
      logReportPageView(this.state.pages.filter((el) => el.id === id)[0]?.name);
    }
    this.setState({
      selectedPage: id,
      selectedChart: null,
      selectedChartMetadata: null,
      layout: this.state.pages
        .find((p) => p.id === id)
        .layout.map((l) => {
          if (this.state.mode == "view") {
            l = { ...l, mode: "view", static: true };
          } else {
            l = { ...l, mode: "edit" };
          }
          return l;
        }),
    });
  }

  setPages(newPages) {
    this.setState({
      pages: newPages,
    });
    this.props.onChange(newPages);
  }

  setSelectedPageMetadata(id, newMetadata) {
    let newPages = this.state.pages.map((p) => {
      if (p.id === id) {
        return produce(p, (tempPage) => {
          Object.keys(tempPage).forEach((k) => {
            tempPage[k] = newMetadata[k];
          });
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
    });

    this.props.onChange(newPages);
  }

  filter(e, chartData, chartArea, chartId) {
    console.log("filtering on the Report Editor");
    if (chartData && chartArea && chartId) {
      this.setState({
        selection: chartData,
        selectedChartArea: chartArea,
        selectedChartAreaIndex: chartArea.index,
      });
    }
  }

  visualPanelIconClick(type) {
    if (!this.state.selectedChart) {
      this.addChart(type);
    } else {
      this.changeChartType(type);
    }
  }

  duplicateKPI(e, i) {
    let duplicatedKPIId = v1();
    let duplicatedKPIMetadata = { ...this.state.layout.filter((l) => l.i === i)[0].metadata };
    let newLayout = [...this.state.layout].concat({
      ...this.state.layout.filter((l) => l.i === i)[0],
      i: duplicatedKPIId,
      x: 18,
      y: 0,
      w: 2,
      h: 2,
    });

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = newLayout;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
      layout: newLayout,
    });

    this.setState({
      selectedChart: duplicatedKPIId,
      selectedChartMetadata: duplicatedKPIMetadata,
    });

    e.stopPropagation();
  }

  removeKPI(e, i) {
    let spreadLayout = [...this.state.layout];
    spreadLayout.splice(
      spreadLayout.findIndex((l) => l.i === i),
      1
    );

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = spreadLayout;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
      layout: spreadLayout,
    });

    if (this.state.selectedChart != null) {
      this.setState({
        selectedChart: null,
        selectedChartMetadata: null,
      });
    }

    this.props.onChange(newPages);

    e.stopPropagation();
  }

  setSelectedChartMetadata(m) {
    // let layoutToUpdate = [...this.state.layout];
    // let objToUpdate = layoutToUpdate.filter(
    //   l => l.i === this.state.selectedChart
    // )[0];
    // objToUpdate = {
    //   ...objToUpdate,
    //   metadata: m
    // };

    // layoutToUpdate.splice(
    //   layoutToUpdate.findIndex(l => l.i === this.state.selectedChart),
    //   1,
    //   objToUpdate
    // );

    let layoutToUpdate = produce(this.state.layout, (tempLayout) => {
      tempLayout.filter((l) => l.i === this.state.selectedChart)[0].metadata = m;
    });

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = layoutToUpdate;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      selectedChartMetadata: m,
      pages: newPages,
      layout: layoutToUpdate,
    });

    this.props.onChange(newPages);
  }

  setChartValue(index, value) {
    let layoutToUpdate = produce(this.state.layout, (tempLayout) => {
      let chartMetadata = tempLayout.filter((l) => l.i === index)[0].metadata;
      chartMetadata.values = value;
      // let newChartMetadata = produce(chartMetadata, tempMetadata => {
      //   tempMetadata.values = value;
      // });
    });

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = layoutToUpdate;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
      layout: layoutToUpdate,
    });

    this.props.onChange(newPages);
  }

  kpiClick(i) {
    this.setState({
      selectedChart: this.state.selectedChart === i ? null : i,
      selectedChartMetadata:
        this.state.selectedChart === i ? null : this.state.layout.filter((l) => l.i === i)[0].metadata,
    });
  }

  // serialize() {
  //   return produce(this.state.layout, tempLayout => {
  //     tempLayout.map(l => (l.map = null));
  //   });
  // }

  generateLayoutItems() {
    if (this.state.layout) {
      return this.state.layout.map((l) => (
        <div key={l.i} static={this.state.mode === "view"}>
          <KSTKKPI
            index={l.i}
            type={l.kpiType}
            mode={this.props.mode}
            dataMode={this.props.dataMode}
            clientReportPermissions={this.props.clientReportPermissions}
            filter={this.props.filter}
            metadata={l.metadata}
            autoUpdateTimerFlag={this.props.autoUpdateTimerFlag}
          />
        </div>
      ));
    }
  }

  addChart(type) {
    let newLayout = [...this.state.layout].concat({
      i: v1(),
      x: 18,
      y: 0,
      w: 2,
      h: 2,
      kpiType: type,
      mode: "edit",
    });

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = newLayout;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
      layout: newLayout,
    });
  }

  changeChartType(type) {
    let newLayout = this.state.layout.map((l) => {
      return produce(l, (tempLayout) => {
        if (tempLayout.i === this.state.selectedChart) {
          tempLayout.kpiType = type;
        }
      });
    });

    let newPages = this.state.pages.map((p) => {
      if (p.id === this.state.selectedPage) {
        return produce(p, (tempPage) => {
          tempPage.layout = newLayout;
        });
      } else {
        return produce(p, (tempPage) => tempPage);
      }
    });

    this.setState({
      pages: newPages,
      layout: newLayout,
    });

    this.props.onChange(newPages);
  }

  createPagePDF() {
    const pdf = new jsPDF("landscape", "pt", "a4");
    html2canvas(document.querySelector(".pdf")).then(function (data) {
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(
        img,
        "PNG",
        pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2,
        pdf.internal.pageSize.getHeight() / 2 - pdfHeight / 2,
        pdfWidth,
        pdfHeight
      );
      setTimeout(() => {
        pdf.save("PDF.pdf");
      }, 1000);
    });
  }

  createReportPDF() {
    const pdf = new jsPDF("landscape", "pt", "a4");
    let reportPDFPages = [];

    this.state.pages.map((p, i) => {
      setTimeout(() => {
        this.setSelectedPage(p.id);
        setTimeout(() => {
          reportPDFPages.push(html2canvas(document.querySelector(".pdf")));
          if (i == this.state.pages.length - 1) {
            this.generateReportPDF(pdf, reportPDFPages);
          }
        }, 1800);
      }, (i + 1) * 2000);
    });
  }

  generateReportPDF(pdf, reportPDFPages) {
    Promise.all(reportPDFPages).then(function (success) {
      for (let i = 0; i < success.length; i++) {
        if (i != 0) {
          pdf.addPage("a4", "l");
        }
        const img = success[i].toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(
          img,
          "PNG",
          pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2,
          pdf.internal.pageSize.getHeight() / 2 - pdfHeight / 2,
          pdfWidth,
          pdfHeight
        );
      }
      pdf.save("PDF.pdf");
    });
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  setKPIWithPreselectedValues(value) {
    let updatedKPIWithPreselectedValues = this.state.kpiWithPreselectedValues;
    updatedKPIWithPreselectedValues.push(value);
    this.setState({ kpiWithPreselectedValues: updatedKPIWithPreselectedValues });
  }

  removeKPIWithPreselectedValues(value) {
    let updatedKPIWithPreselectedValues = this.state.kpiWithPreselectedValues;
    updatedKPIWithPreselectedValues.splice(updatedKPIWithPreselectedValues.indexOf(value), 1);
    this.setState({ kpiWithPreselectedValues: updatedKPIWithPreselectedValues });
  }

  handleDragStart(
    layout: Layout,
    oldItem: LayoutItem,
    newItem: LayoutItem,
    placeholder: LayoutItem,
    e: MouseEvent,
    element: HTMLElement
  ) {
    element.children[0].style.display = "none";
    element.children[1].style.display = "none";
  }
  handleDragStop(
    layout: Layout,
    oldItem: LayoutItem,
    newItem: LayoutItem,
    placeholder: LayoutItem,
    e: MouseEvent,
    element: HTMLElement
  ) {
    element.children[0].style.display = "grid";
    element.children[1].style.display = "block";
  }

  handleResizeStart(
    layout: Layout,
    oldItem: LayoutItem,
    newItem: LayoutItem,
    placeholder: LayoutItem,
    e: MouseEvent,
    element: HTMLElement
  ) {
    element.previousSibling.style.display = "none";
  }
  handleResizeStop(
    layout: Layout,
    oldItem: LayoutItem,
    newItem: LayoutItem,
    placeholder: LayoutItem,
    e: MouseEvent,
    element: HTMLElement
  ) {
    element.previousSibling.style.display = "grid";
  }

  removeOverflowKPIs(event) {
    if (this.state.overflowVisuals != null && this.state.overflowVisuals.length > 0) {
      let spreadLayout = [...this.state.layout];
      this.state.overflowVisuals.forEach((overflowVisualId) => {
        this.removeKPI(event);
        spreadLayout.splice(
          spreadLayout.findIndex((l) => l.i === overflowVisualId),
          1
        );
      });

      let newPages = this.state.pages.map((p) => {
        if (p.id === this.state.selectedPage) {
          return produce(p, (tempPage) => {
            tempPage.layout = spreadLayout;
          });
        } else {
          return produce(p, (tempPage) => tempPage);
        }
      });

      this.setState({
        pages: newPages,
        layout: spreadLayout,
      });

      if (this.state.selectedChart != null) {
        this.setState({
          selectedChart: null,
          selectedChartMetadata: null,
        });
      }

      this.props.onChange(newPages);

      event.stopPropagation();
    }
  }

  render() {
    let { width, height } = this.props.size;

    // Remove necessary width to make report fit above the page select nav
    if (this.state.mode == "view") {
      width = width - 72;
    }
    if (height < width) {
      for (let index = height; index > 0; index--) {
        if (index * (16 / 9) < width) {
          width = index * (16 / 9);
          height = index;
          break;
        }
      }
    } else if (width < height) {
      for (let index = width; index > 0; index--) {
        if (index * (9 / 16) < height) {
          height = index * (9 / 16);
          width = index;
          break;
        }
      }
    } else {
      height = width * (9 / 16);
    }
    const scale = Math.min(width / this.state.originalWidth, height / this.state.originalHeight);
    let style = {
      width: width,
      height: height,
    };
    /*  if (this.state.mode == "view" && scale < 1) {
      style.transform = "scale(" + scale + ")";
    } */
    const numberOfRows = 20;
    const marginsMergedHeight = 210;
    const rowHeight = height / numberOfRows - marginsMergedHeight / numberOfRows;

    let onGridLayoutChange = (layout) => {
      let mergedLayout = this.state.layout.map((l) => {
        return produce(l, (tempLayout) => {
          const foundUpdatesList = layout.filter((tp) => tp.i === l.i);
          const foundUpdate = foundUpdatesList.length ? foundUpdatesList[0] : null;

          if (foundUpdate) {
            tempLayout.x = foundUpdate.x;
            tempLayout.y = foundUpdate.y;
            tempLayout.w = foundUpdate.w;
            tempLayout.h = foundUpdate.h;
          }
        });
      });

      let newPages = this.state.pages.map((p) => {
        if (p.id === this.state.selectedPage) {
          return produce(p, (tempPage) => {
            tempPage.layout = mergedLayout;
          });
        } else {
          return produce(p, (tempPage) => tempPage);
        }
      });

      this.setState({
        pages: newPages,
        layout: mergedLayout,
      });

      this.props.onChange(newPages);
    };

    return (
      <Wrapper mode={this.state.mode} className="reportSetup">
        <ReportContext.Provider value={this.state}>
          {this.state.mode == "edit" ? (
            <React.Fragment>
              <NavWrapper className={"editMode"}>
                <StyledNav tabs>
                  {this.state.pages.map((p, i) => {
                    return (
                      <StyledNavItem key={i}>
                        <ReportPageNavLink
                          className={classnames({
                            active: this.state.selectedPage === p.id,
                          })}
                          onClick={() => {
                            this.setSelectedPage(p.id);
                          }}
                        >
                          {p.name}
                          <Button color="link" onClick={(e) => this.duplicatePage(e, p.id)}>
                            <i className="fas fa-clone" />
                          </Button>
                          <Button color="link" onClick={(e) => this.removePage(e, p.id)}>
                            <i className="fas fa-times-circle" />
                          </Button>
                        </ReportPageNavLink>
                      </StyledNavItem>
                    );
                  })}
                </StyledNav>
                <Spacer></Spacer>
                <Button color="link" onClick={() => this.addPage()}>
                  <i className="fas fa-plus-square" />
                </Button>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                  <DropdownToggle caret size="sm" color="primary">
                    Exportar PDF
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={() => this.createReportPDF()}>Exportar relatório</DropdownItem>
                    <DropdownItem onClick={() => this.createPagePDF()}>Exportar página selecionada</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavWrapper>
              <KSTKSplitView
                left={(width) => (
                  <GridLayout
                    className="layout pdf"
                    layout={this.state.layout}
                    cols={20}
                    rowHeight={rowHeight}
                    width={width}
                    draggableCancel="input,textarea,Select"
                    compactType={null}
                    autoSize={false}
                    style={style}
                    maxRows={numberOfRows}
                    onLayoutChange={onGridLayoutChange}
                    preventCollision={true}
                    onDrag={this.handleDragStart}
                    onDragStop={this.handleDragStop}
                    onResizeStart={this.handleResizeStart}
                    onResizeStop={this.handleResizeStop}
                  >
                    {this.generateLayoutItems()}
                  </GridLayout>
                )}
                right={<KSTKVisualsSidePanel></KSTKVisualsSidePanel>}
                padding={30}
              ></KSTKSplitView>
              {/* <KSTKFieldsSidePanel /> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GridLayoutWrapper>
                <GridLayout
                  className="layout pdf"
                  layout={this.state.layout}
                  cols={20}
                  rowHeight={rowHeight}
                  width={width}
                  draggableCancel="input,textarea,Select"
                  compactType={null}
                  autoSize={false}
                  style={style}
                  maxRows={numberOfRows}
                  onLayoutChange={onGridLayoutChange}
                  preventCollision={true}
                >
                  {this.generateLayoutItems()}
                </GridLayout>
              </GridLayoutWrapper>
              <NavWrapper className={"viewMode"}>
                {getIsAdmin() ? (
                  <Button onClick={this.props.switchReportMode} color="primary">
                    Edit <i className="fa-solid fa-pencil" />
                  </Button>
                ) : null}
                <PageSelect
                  isMulti={false}
                  isSearchable={true}
                  defaultValue={this.state.pages[0]}
                  options={this.state.pages.filter((p) => p.hidden == null || p.hidden == false)}
                  getOptionLabel={(optionPage) => optionPage.name}
                  getOptionValue={(p) => p.id}
                  onChange={(page) => this.setSelectedPage(page.id)}
                  styles={{
                    control: (baseStyles, state) => {
                      return {
                        ...baseStyles,
                        fontWeight: "600",
                      };
                    },
                  }}
                />
                <span style={{ fontWeight: "600" }}>
                  {this.state.pages
                    ?.filter((p) => p.hidden == null || p.hidden == false)
                    .map((p) => p.id)
                    .indexOf(this.state.selectedPage) +
                    1 +
                    "/" +
                    this.state.pages.filter((p) => p.hidden == null || p.hidden == false).length}
                </span>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                  <DropdownToggle caret color="secondary">
                    Exportar PDF
                  </DropdownToggle>
                  <DropdownMenu end>
                    {/* <DropdownItem onClick={() => this.createReportPDF()}>Exportar relatório</DropdownItem> */}
                    <DropdownItem onClick={() => this.createPagePDF()}>Exportar página selecionada</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavWrapper>
            </React.Fragment>
          )}
        </ReportContext.Provider>
      </Wrapper>
    );
  }
}

export default sizeMe({ monitorHeight: true, monitorWidth: true })(
  connect(mapStateToProps, mapDispatchToProps)(KSTKReportEditor)
);
