import React, { useContext, useEffect } from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { ReportContext } from "./ReportContext";

const CardText = styled.div`
  text-align: center;
`;

export function KSTKCard(props) {
  const { setChartValue, setSelectedChartMetadata } = useContext(ReportContext);

  const numberToLocaleDateString = (number) => {
    let n = JSON.parse(JSON.stringify(number));
    return n != null ? n.toLocaleString("pt-PT") : "";
  };

  return (
    <CardText
      style={{
        fontSize: props.metadata?.cardFontSize ? props.metadata.cardFontSize + "px" : "1.2vw",
        fontFamily: props.metadata?.cardFontFamily
          ? props.metadata.cardFontFamily
          : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        color: props.metadata?.cardTextColor ? props.metadata.cardTextColor : null,
        backgroundColor: props.metadata?.cardBackgroundColor ? props.metadata.cardBackgroundColor : null,
        fontWeight: props.metadata?.cardFontBold ? 700 : 500,
        fontStyle: props.metadata?.cardFontItalic ? "italic" : "initial",
        textDecoration: props.metadata?.cardFontUnderline ? "underline" : "none",
      }}
    >
      {props.data && props.data.datasets && props.data.datasets.length
        ? ((props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
            (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")) &&
          !isNaN(Number(props.data.datasets[0].data[props.data.datasets[0].data.length - 1]))
          ? props.getCardParsedValue(props.data.datasets[0].data[props.data.datasets[0].data.length - 1])
          : !isNaN(Number(props.data.datasets[0].data[props.data.datasets[0].data.length - 1]))
          ? numberToLocaleDateString(props.data.datasets[0].data[props.data.datasets[0].data.length - 1])
          : props.data.datasets[0].data[props.data.datasets[0].data.length - 1]
        : ""}
    </CardText>
  );
}
