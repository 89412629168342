import React, { Component } from "react";
import styled from "styled-components";
import {
  Input,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const Wrapper = styled.div`
  padding: 0px 8px;
`;

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: auto;
`;

const ListControls = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 16px;

  & input {
    width: 200px;
    align-self: center;
  }

  & div {
    display: flex;
  }

  & button {
    align-self: center;
  }
`;

const ReportCategory = styled.h3``;

const CategoriesReportsWrapper = styled.div`
  padding: 8px;
`;

const FlexCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionableCardHeader = styled(CardTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionableCardTitle = styled(CardTitle)`
  display: flex;
  align-items: center;
`;

const ActionableCardOperations = styled(CardTitle)`
  display: flex;
  align-items: center;
`;

const CardSpacer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: auto;
`;

const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-around;
`;

const FixedCardText = styled.div`
  display: flex;
  height: 30px;
  overflow: auto;
`;

const ReportCard = styled(Card)`
  max-width: 210px;
  min-width: 210px;
  max-height: 210px;
  min-height: 210px;
  margin: 10px;
`;

export default class KSTKCardList extends Component {
  constructor(props) {
    super(props);
    this.searchData = this.searchData.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.groupBy2 = this.groupBy2.bind(this);

    this.state = {
      isDeleteModalOpen: false,
      selectedItem: null,
    };
  }

  toggleDeleteModal(item) {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      selectedItem: item == null || item == undefined ? null : item,
    });
  }

  searchData(e) {
    const search = e.target.value.toLowerCase();
    this.props.searchData(search);
  }

  groupBy2(xs, prop) {
    var grouped = {};
    for (var i = 0; i < xs.length; i++) {
      var p = xs[i][prop];
      if (!grouped[p]) {
        grouped[p] = [];
      }
      grouped[p].push(xs[i]);
    }
    return Object.values(grouped);
  }

  render() {
    return (
      <Wrapper>
        <Modal isOpen={this.state.isDeleteModalOpen} toggle={() => this.toggleDeleteModal()} centered={true}>
          <ModalHeader toggle={() => this.toggleDeleteModal()}>Delete item?</ModalHeader>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.toggleDeleteModal();
                this.props.delete(this.state.selectedItem);
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggleDeleteModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <ListControls>
          <h1>{this.props.title}</h1>
          <div>
            <Button onClick={this.props.create} color="primary">
              New
            </Button>
          </div>
          <Input name="search" id="search" placeholder={this.props.title + " search..."} onChange={this.searchData} />
        </ListControls>
        {this.props.metadata ? (
          this.groupBy2(this.props.metadata, "parsedCategory").map((reportsByCategory) => {
            return (
              <CategoriesReportsWrapper key={reportsByCategory[0].id + "1"}>
                <ReportCategory>
                  {reportsByCategory[0].parsedCategory != null ? (
                    <span>{reportsByCategory[0].parsedCategory}</span>
                  ) : null}
                </ReportCategory>
                <CardList>
                  {reportsByCategory ? (
                    reportsByCategory.map((r) => {
                      return (
                        <ReportCard body key={r.id}>
                          <FlexCardBody>
                            <ActionableCardHeader>
                              <ActionableCardTitle>{r.name}</ActionableCardTitle>
                              <ActionableCardOperations>
                                {this.props.duplicate ? (
                                  <Button color="link" onClick={() => this.props.duplicate(r)}>
                                    <i className="fas fa-copy" />
                                  </Button>
                                ) : null}
                                <Button color="link" onClick={() => this.toggleDeleteModal(r)}>
                                  <i className="fas fa-trash" />
                                </Button>
                              </ActionableCardOperations>
                            </ActionableCardHeader>
                            {r.createdAt ? (
                              <p>
                                <i className="fas fa-clock" />
                                {" " +
                                  new Date(r.createdAt).toDateString() +
                                  ", " +
                                  new Date(r.createdAt).getHours() +
                                  ":" +
                                  (new Date(r.createdAt).getMinutes().toString().length == 1
                                    ? "0" + new Date(r.createdAt).getMinutes()
                                    : new Date(r.createdAt).getMinutes())}
                              </p>
                            ) : null}
                            {r.modifiedAt ? (
                              <p>
                                <i className="fas fa-pen" />
                                {" " +
                                  new Date(r.modifiedAt).toDateString() +
                                  ", " +
                                  new Date(r.modifiedAt).getHours() +
                                  ":" +
                                  (new Date(r.modifiedAt).getMinutes().toString().length == 1
                                    ? "0" + new Date(r.modifiedAt).getMinutes()
                                    : new Date(r.modifiedAt).getMinutes())}
                              </p>
                            ) : null}
                            <FixedCardText>{r.description}</FixedCardText>
                            <CardSpacer></CardSpacer>
                            <CardButtons>
                              <Button onClick={() => this.props.action(r, "edit")} color="primary" outline>
                                Edit
                              </Button>
                              {this.props.view ? (
                                <Button onClick={() => this.props.action(r, "view")} color="primary" outline>
                                  View
                                </Button>
                              ) : null}
                            </CardButtons>
                          </FlexCardBody>
                        </ReportCard>
                      );
                    })
                  ) : (
                    <div>Vazio</div>
                  )}
                </CardList>
              </CategoriesReportsWrapper>
            );
          })
        ) : (
          <div>Vazio</div>
        )}
      </Wrapper>
    );
  }
}
