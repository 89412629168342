import React, { useContext } from "react";
import styled from "styled-components";
import { KSTKSelect } from "./KSTKSelect";
import { ColumnTypes } from "../util/report.util";

import type { ChartMetadataRowColumnAggregator } from "./KSTKComponentTypes";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
`;

export function KSTKColumnAggregatorFieldSidePanel(props) {
  const fieldOperations = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
    { value: "count", label: "Count" },
    { value: "count_dist", label: "Count (Distinct)" },
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
  ];

  const getFieldValue = () => {
    if (props.value) {
      switch (typeof props.value) {
        case "string":
          return props.fields.filter((o) => o.type === ColumnTypes.COLUMN).filter((o) => o.value === props.value);
          break;
        case "object":
          return props.fields
            .filter((o) => o.type === ColumnTypes.MEASURE)
            .filter((o) => o.value.id === props.value.id);
          break;
      }
    }

    return [];
  };

  const getAggregatorValue = () => {
    if (props.operationValue) {
      return fieldOperations.filter((f) => f.value === props.operationValue);
    } else {
      return [];
    }
  };

  const handleFieldChange = (value) => {
    let changeValue: ChartMetadataRowColumnAggregator = {};
    let aggregator = getAggregatorValue();
    changeValue.field = value && value.value ? value.value : "";
    changeValue.aggregator = aggregator && aggregator.length ? getAggregatorValue()[0].value : "";

    props.onChange(changeValue);
  };

  const handleAggregatorChange = (value) => {
    let changeValue: ChartMetadataRowColumnAggregator = {};
    let field = getFieldValue();
    changeValue.field = field && field.length ? getFieldValue()[0].value : "";
    changeValue.aggregator = value && value.value ? value.value : "";

    props.onChange(changeValue);
  };

  return (
    <Wrapper>
      <KSTKSelect
        id="label"
        placeholder="Aggregator"
        isMulti={false}
        isSearchable="false"
        isClearable="false"
        options={fieldOperations}
        onChange={(value) => handleAggregatorChange(value)}
        value={getAggregatorValue()}
        isDisabled={props.isDisabled}
      />
      <KSTKSelect
        id="label"
        placeholder="Column"
        isMulti={false}
        isSearchable="true"
        isClearable="false"
        options={props.options}
        onChange={(value) => handleFieldChange(value)}
        value={getFieldValue()}
        isDisabled={props.isDisabled}
      />
      {/* <i className="far fa-times-circle" onClick={props.onDelete} /> */}
    </Wrapper>
  );
}
