export const PUBLISH_NOTIFICATION = "PUBLISH_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const publishNotification = (metadata) => (dispatch) => {
  dispatch({
    type: PUBLISH_NOTIFICATION,
    payload: metadata,
  });
};

export const removeNotification = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: id,
  });
};
