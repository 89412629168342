import React from "react";
import styled from "styled-components";

const Label = styled.div`
  font-size: 0.7em;
  font-weight: 600;
  color: darkgray;
`;

const KSTKReadOnlyFieldWrapper = styled.div`
  width: 100%;
`;

export function KSTKReadOnlyField(props) {
  return (
    <KSTKReadOnlyFieldWrapper>
      <Label>{props.label}</Label>
      <div>{props.value}</div>
    </KSTKReadOnlyFieldWrapper>
  );
}
