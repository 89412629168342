import React, { Component } from "react";
import KSTKCardList from "../components/KSTKCardList";
import {
  getDatasources,
  createDatasource as createDatasourceService,
  deleteDatasource as deleteDatasourceService,
} from "../services/datasource.service";

export default class DataSourceList extends Component {
  constructor(props) {
    super(props);
    this.goToDataSource = this.goToDataSource.bind(this);
    this.createDataSource = this.createDataSource.bind(this);
    this.deleteDataSource = this.deleteDataSource.bind(this);
    this.duplicateDatasource = this.duplicateDatasource.bind(this);

    this.duplicateDatasourceFlag = false;

    getDatasources().then((data) => {
      this.props.fetchDataSources(data);
    });
    document.title = "KSTK Report Builder";
  }

  goToDataSource(ds) {
    this.props.selectDataSource(ds.id);
    this.props.history.push(`/datasources/${ds.id}`);
  }

  componentDidUpdate(prevProps) {
    if (this.duplicateDatasourceFlag) {
      this.duplicateDatasourceFlag = false;
      return;
    }

    if (
      prevProps.selectedDataSource != null &&
      prevProps.selectedDataSource.length != 0 &&
      prevProps.selectedDataSource[0] != null &&
      this.props.selectedDataSource != null &&
      this.props.selectedDataSource.length != 0 &&
      this.props.selectedDataSource[0] != null &&
      prevProps.selectedDataSource[0].id !== this.props.selectedDataSource[0].id
    ) {
      this.goToDataSource(this.props.selectedDataSource[0]);
    }

    if (
      prevProps.selectedDataSource != null &&
      prevProps.selectedDataSource.length != 0 &&
      prevProps.selectedDataSource[0] === undefined &&
      this.props.selectedDataSource.length != 0 &&
      this.props.selectedDataSource[0] != null
    ) {
      this.goToDataSource(this.props.selectedDataSource[0]);
    }
  }

  createDataSource() {
    createDatasourceService().then((data) => this.props.createDataSource(data));
  }

  deleteDataSource(d) {
    deleteDatasourceService(d.id).then((id) => {
      getDatasources().then((data) => {
        this.props.fetchDataSources(data);
      });
    });
  }

  duplicateDatasource(d) {
    this.duplicateDatasourceFlag = true;
    let datasourceOriginalName = d.name;
    let duplicateDatasource = JSON.parse(JSON.stringify(d));
    delete duplicateDatasource.id;
    delete duplicateDatasource.modifiedAt;
    duplicateDatasource.createdAt = new Date().getTime();
    for (let i = 1; i < this.props.datasources.length; i++) {
      if (this.props.datasources.find((r) => r.name === datasourceOriginalName + " (" + i + ")") == null) {
        duplicateDatasource.name = datasourceOriginalName + " (" + i + ")";
        break;
      }
    }
    createDatasourceService(duplicateDatasource).then((data) => {
      this.props.createDataSource(data);
      getDatasources().then((data) => {
        this.props.fetchDataSources(data);
      });
    });
  }

  render() {
    return (
      <KSTKCardList
        title="Datasources"
        metadata={this.props.datasources}
        searchData={this.props.searchDataSources}
        action={this.goToDataSource}
        create={this.createDataSource}
        delete={this.deleteDataSource}
        duplicate={this.duplicateDatasource}
        view={false}
      />
    );
  }
}
