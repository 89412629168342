import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { v1 as uuid } from "uuid";
import KSTKReportSourcesEditor from "../components/KSTKReportSourcesEditor";
import KSTKEmptyContent from "../components/KSTKEmptyContent";
import KSTKItemList from "../components/KSTKItemList";
import KSTKDropdown from "../components/KSTKDropdown";
import KSTKMeasureEditor from "../components/KSTKMeasureEditor";
import { KSTKSelect } from "../components/KSTKSelect";
import { updateDatasource, generateFromOrg } from "../services/datasource.service";
import { getModulesMetadata } from "../services/module.service";
import { getOrganizations } from "../services/organization.service";
import { getColumnsFromDatasource } from "../util/report.util";
import produce from "immer";

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 85%;
`;

const MeasuresEditorWrapper = styled.div`
  height: 100%;
`;

const VariablesWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;

  & input {
    width: 200px;
  }

  & > div {
    width: 200px;
  }

  & > * {
    margin: 0 10px 0px 0;
    align-self: center;
  }
`;

const ItemListWrapper = styled.div`
  height: 100%;
`;

const DataSourceForm = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;

  & input {
    width: 200px;
  }

  & > div {
    width: 200px;
  }

  & > * {
    margin: 0 10px 0px 0;
    align-self: center;
  }
`;

const TopControls = styled.div`
  height: 100%;
  display: flex;
`;

const LeftArea = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 100%;
  width: 70%;
`;

const RightArea = styled.div`
  width: 30%;
  height: 100%;
  display: grid;
  justify-content: end;
  align-content: flex-start;
`;

const StyledNavItem = styled(NavItem)`
  a {
    &.active {
      font-weight: 700;
    }
  }
`;

const StyledTabContent = styled(TabContent)`
  height: calc(100% - 42px);
`;

const StyledTabPane = styled(TabPane)`
  height: 100%;
  padding: 20px 10px 10px 10px;
`;

const SourcesContent = styled.div`
  padding-top: 10px;
`;
export default class DataSource extends Component {
  constructor(props) {
    super(props);
    this.updateDataSourceMetadata = this.updateDataSourceMetadata.bind(this);
    this.upsertSource = this.upsertSource.bind(this);
    this.onSourceItemSelected = this.onSourceItemSelected.bind(this);
    this.onSourceItemDeselected = this.onSourceItemDeselected.bind(this);
    this.onSourceItemDeleted = this.onSourceItemDeleted.bind(this);
    this.onSourceASelect = this.onSourceASelect.bind(this);
    this.onColumnASelect = this.onColumnASelect.bind(this);
    this.onOperatorsSelect = this.onOperatorsSelect.bind(this);
    this.onSourceBSelect = this.onSourceBSelect.bind(this);
    this.onColumnBSelect = this.onColumnBSelect.bind(this);
    this.upsertRelation = this.upsertRelation.bind(this);
    this.upsertVariable = this.upsertVariable.bind(this);
    this.onRelationItemDeleted = this.onRelationItemDeleted.bind(this);
    this.fillFromOrganizationOrModule = this.fillFromOrganizationOrModule.bind(this);
    this.filterDatasourceResources = this.filterDatasourceResources.bind(this);
    this.onRelationItemSelected = this.onRelationItemSelected.bind(this);
    this.onRelationItemDeselected = this.onRelationItemDeselected.bind(this);
    this.onMeasureEditorChange = this.onMeasureEditorChange.bind(this);
    this.onSourcesEditorChange = this.onSourcesEditorChange.bind(this);
    this.onVariableItemSelected = this.onVariableItemSelected.bind(this);
    this.onVariableItemDeselected = this.onVariableItemDeselected.bind(this);
    this.onVariableItemDeleted = this.onVariableItemDeleted.bind(this);

    this.state = {
      newSourceName: "",
      newSourceSchema: "",
      newRelationId: null,
      newRelationSourceA: "",
      newRelationColumnA: "",
      newRelationOperator: "",
      newRelationSourceB: "",
      newRelationColumnB: "",
      newDatasourceName:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].name : null,
      newDatasourceDescription:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].description : null,
      newDatasourceTags:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].tags : null,
      activeTab: "1",
      selectedOrg: null,
      selectedModule: null,
      sourceType:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].sourceType : null,
      module:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].module : null,
      organizationList: [],
      newCustomHeaders:
        this.props.datasource != null &&
        this.props.datasource.length > 0 &&
        this.props.datasource[0].customHeaders == null
          ? {}
          : this.props.datasource[0].customHeaders,
      newCustomModelations:
        this.props.datasource != null &&
        this.props.datasource.length > 0 &&
        this.props.datasource[0].customModelations == null
          ? {}
          : this.props.datasource[0].customModelations,
      newCustomDataTypes:
        this.props.datasource != null &&
        this.props.datasource.length > 0 &&
        this.props.datasource[0].customDataTypes == null
          ? {}
          : this.props.datasource[0].customDataTypes,
      newCustomColumns:
        this.props.datasource != null &&
        this.props.datasource.length > 0 &&
        this.props.datasource[0].customColumns == null
          ? {}
          : this.props.datasource[0].customColumns,
      measures: this.props?.datasource[0]?.measures,
      primaryKeys:
        this.props.datasource[0]?.resources?.map((r) => {
          const currentTable = `${r.schema}.${r.name}`;
          return {
            currentTable: r.primaryKey,
          };
        }) || {},
      resources: this.props.datasource[0]?.resources,
      filteredDatasourceResources:
        this.props.datasource != null && this.props.datasource.length > 0 ? this.props.datasource[0].resources : null,
      newVariable: "",
      newVariableValues: "",
    };

    this.newRelationSourceARef = React.createRef();
    this.newRelationColumnARef = React.createRef();
    this.newRelationOperatorRef = React.createRef();
    this.newRelationSourceBRef = React.createRef();
    this.newRelationColumnBRef = React.createRef();

    this.sourceColumns = [
      { key: "name", name: "Name" },
      { key: "schema", name: "Schema" },
    ];

    this.relationColumns = [
      { key: "sourceA", name: "Source A" },
      { key: "columnA", name: "Column A" },
      { key: "operator", name: "Operator" },
      { key: "sourceB", name: "Source B" },
      { key: "columnB", name: "Column B" },
    ];

    this.variableColumns = [
      { key: "variableName", name: "Variable Name" },
      { key: "variableValues", name: "Variable Values" },
    ];

    this.relationOperators = ["INNER JOIN", "LEFT JOIN", "RIGHT JOIN"];

    this.updateOrganizationsList();
    this.updateMongoModulesList();
  }

  updateOrganizationsList() {
    getOrganizations().then((orgs) => {
      this.setState({
        organizationList: orgs.sort(function (a, b) {
          if (a["name"].localeCompare(b["name"], "pt") == -1) {
            return -1;
          }
          if (a["name"].localeCompare(b["name"], "pt") == 1) {
            return 1;
          }
        }),
      });
    });
  }

  updateMongoModulesList() {
    getModulesMetadata().then((metadatas) =>
      this.setState({
        moduleList: metadatas
          /* .filter((el) => el.value != "class_plan_school_record" && el.value != "class_plan_prof_school_record") */
          .sort(function (a, b) {
            if (a["name"].localeCompare(b["name"], "pt") == -1) {
              return -1;
            }
            if (a["name"].localeCompare(b["name"], "pt") == 1) {
              return 1;
            }
          })
          .map((el) => {
            el.name = el.name + " - " + el.collection;
            return el;
          }),
      })
    );
  }

  getCurrentDatasourceRepresentation() {
    const currentPrimaryKeys = this.state.primaryKeys;
    return {
      ...this.props.datasource[0],
      // this.setState({
      //   datasource: produce(this.state.datasource, d => {
      //     return d
      //     .resources
      //     .find(r => r.name === this.state?.selected?.name && r.schema === this.state?.selected?.schema)
      //     ?.primaryKey = newPrimaryKey.value;
      //   })
      // })
      name: this.state.newDatasourceName,
      description: this.state.newDatasourceDescription,
      tags: this.state.newDatasourceTags,
      customHeaders: this.state.newCustomHeaders,
      customModelations: this.state.newCustomModelations,
      customDataTypes: this.state.newCustomDataTypes,
      customColumns: this.state.newCustomColumns,
      measures: this.state.measures,
      modifiedAt: new Date().getTime(),
      sourceType: this.state.sourceType,
      module: this.state.module,
      resources: this.state.filteredDatasourceResources,
    };
  }

  updateDataSourceMetadata(e) {
    updateDatasource(this.getCurrentDatasourceRepresentation()).then((data) => {
      this.props.updateMetadata(
        {
          name: data.newDatasourceName,
          description: data.newDatasourceDescription,
          tags: data.newDatasourceTags,
          customHeaders: this.state.newCustomHeaders,
          customModelations: this.state.newCustomModelations,
          customDataTypes: this.state.newCustomDataTypes,
          customColumns: this.state.newCustomColumns,
          modifiedAt: new Date().getTime(),
          primaryKeys: data.primaryKeys,
          sourceType: data.sourceType,
          module: data.module,
          resources: data.resources,
        },
        data.id
      );
    });

    e.preventDefault();
  }

  upsertSource(e) {
    this.props.upsertSource(
      {
        name: this.state.newSourceName,
        schema: this.state.newSourceSchema,
      },
      this.props.datasource[0].id
    );

    this.setState({
      newSourceName: "",
      newSourceSchema: "",
    });

    e.preventDefault();
  }

  onSourceItemSelected(item) {
    this.setState({
      newSourceName: item.name,
      newSourceSchema: item.schema,
    });
  }

  onSourceItemDeselected(item) {
    this.setState({
      newSourceName: "",
      newSourceSchema: "",
    });
  }

  onSourceItemDeleted(item) {
    this.setState({
      filteredDatasourceResources: this.state.filteredDatasourceResources.filter((r) => r.id != item.id),
    });
    this.props.removeSource(item, this.props.datasource[0].id);
  }

  tableEntryLabel(data) {
    return data ? `${data.schema}.${data.name}` : "";
  }

  onSourceASelect(s) {
    this.setState({
      newRelationSourceA: s ? this.tableEntryLabel(s) : "",
      availableColumnAValue: s
        ? this.props.datasource[0].resources.find((r) => this.tableEntryLabel(r) === this.tableEntryLabel(s)).columns
        : "",
    });
  }

  onColumnASelect(c) {
    if (c) {
      this.setState({
        newRelationColumnA: c ? c.name : "",
      });
    }
  }

  onOperatorsSelect(o) {
    this.setState({
      newRelationOperator: o ? o : "",
    });
  }

  onSourceBSelect(s) {
    this.setState({
      newRelationSourceB: s ? this.tableEntryLabel(s) : "",
      availableColumnBValue: s
        ? this.props.datasource[0].resources.find((r) => this.tableEntryLabel(r) === this.tableEntryLabel(s)).columns
        : "",
    });
  }

  onColumnBSelect(c) {
    if (c) {
      this.setState({
        newRelationColumnB: c ? c.name : "",
      });
    }
  }

  upsertRelation(e) {
    if (
      this.state.newRelationSourceA != "" &&
      this.state.newRelationColumnA != "" &&
      this.state.newRelationSourceB != "" &&
      this.state.newRelationColumnB != "" &&
      this.state.newRelationOperator != ""
    )
      this.props.upsertRelation(
        {
          id: this.state.newRelationId ? this.state.newRelationId : uuid(),
          sourceA: this.state.newRelationSourceA,
          columnA: this.state.newRelationColumnA,
          sourceB: this.state.newRelationSourceB,
          columnB: this.state.newRelationColumnB,
          operator: this.state.newRelationOperator,
        },
        this.props.datasource[0].id
      );

    if (this.state.newRelationId == null) {
      this.newRelationSourceARef.current.reset();
      this.newRelationColumnARef.current.reset();
      this.newRelationSourceBRef.current.reset();
      this.newRelationColumnBRef.current.reset();
      this.newRelationOperatorRef.current.reset();

      this.setState({
        newRelationSourceA: "",
        newRelationColumnA: "",
        newRelationSourceB: "",
        newRelationColumnB: "",
        newRelationOperator: "",
        newRelationId: null,
      });
    }

    e.preventDefault();
  }

  upsertVariable(e) {
    if (this.state.newVariable != "" && this.state.newVariableValues != "")
      this.props.upsertVariable(
        {
          variableName: this.state.newVariable,
          variableValues: this.state.newVariableValues,
        },
        this.props.datasource[0].id
      );

    this.onVariableItemDeselected();

    //if (this.state.newVaribleName == null) {
    //  this.newRelationSourceARef.current.reset();
    //  this.newRelationColumnARef.current.reset();
    //  this.newRelationSourceBRef.current.reset();
    //  this.newRelationColumnBRef.current.reset();
    //  this.newRelationOperatorRef.current.reset();

    //  this.setState({
    //    newRelationSourceA: "",
    //    newRelationColumnA: "",
    //    newRelationSourceB: "",
    //    newRelationColumnB: "",
    //    newRelationOperator: "",
    //    newRelationId: null,
    //  });
    //}

    e.preventDefault();
  }

  onRelationItemDeleted(item) {
    this.props.removeRelation(item, this.props.datasource[0].id);
  }

  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  fillFromOrganizationOrModule() {
    if (this.state.selectedModule) {
      let datasourceObjArr = [];
      for (const tabName in this.state.selectedModule.tabs) {
        if (Object.hasOwnProperty.call(this.state.selectedModule.tabs, tabName)) {
          const tab = this.state.selectedModule.tabs[tabName];
          let datasourceObj = {};
          datasourceObj.id = uuid();
          datasourceObj.name = tabName;
          datasourceObj.schema = "public";
          datasourceObj.type = "table";
          datasourceObj.customFunctions = tab.customFunctions;
          datasourceObj.tabIndex = Object.keys(this.state.selectedModule.tabs).indexOf(tabName);
          let fields = tab.fields;
          let columns = [];
          for (const field in fields) {
            if (Object.hasOwnProperty.call(fields, field)) {
              const fieldAttributes = fields[field];
              if (fieldAttributes.type != null) {
                columns.push({ name: field, type: fieldAttributes.type });
              }
            }
          }
          datasourceObj.columns = columns;
          datasourceObjArr.push(datasourceObj);
        }
      }
      let resources = [];
      if (this.props.datasource && this.props.datasource[0] && this.props.datasource[0].resources?.length) {
        resources = this.filterDatasourceResources(this.props.datasource[0].resources, datasourceObjArr);
      } else {
        resources = datasourceObjArr;
      }
      this.props.setSources(resources, "mongo", this.props.datasource[0].id);
      this.setState({
        sourceType: "mongo",
        module: this.state.selectedModule.id,
        filteredDatasourceResources: resources,
      });
    } else if (this.state.selectedOrg) {
      generateFromOrg(this.state.selectedOrg.id).then((data) => {
        if (data) {
          data = data.map((d) => {
            d.id = uuid();
            return d;
          });
          let resources = [];
          if (this.props.datasource && this.props.datasource[0] && this.props.datasource[0].resources?.length) {
            resources = this.filterDatasourceResources(this.props.datasource[0].resources, data);
          } else {
            resources = data;
          }
          this.props.setSources(resources, "postgres", this.props.datasource[0].id);
          this.setState({
            sourceType: "postgres",
            module: null,
            filteredDatasourceResources: resources,
          });
        }
      });
    }
  }

  filterDatasourceResources(existingTables, newTables) {
    let resources = [];
    // Add only new tables and keep the existing
    resources = resources.concat(existingTables);
    let existingTablesNames = existingTables.map((el) => el.name);
    let newResources = newTables.filter((el) => existingTablesNames.indexOf(el.name) == -1);
    if (newResources.length) {
      resources = resources.concat(newResources);
    }
    resources.forEach((r) => {
      const existingTable = newTables.find((el) => el.name === r.name && el.schema === r.schema);

      if (existingTable != null) {
        r.columns = existingTable.columns;
      }
    });
    return resources;
  }

  onSourcesEditorChange = (customHeaders, customModelations, customDataTypes, customColumns, primaryKeys) => {
    if (customHeaders) {
      this.setState({
        newCustomHeaders: customHeaders,
      });
    }
    if (customModelations) {
      this.setState({
        newCustomModelations: customModelations,
      });
    }
    if (customDataTypes) {
      this.setState({
        newCustomDataTypes: customDataTypes,
      });
    }
    if (customColumns) {
      let validCustomColumns = { ...customColumns };
      for (const source in validCustomColumns) {
        if (Object.hasOwnProperty.call(validCustomColumns, source)) {
          let cColumns = validCustomColumns[source];
          validCustomColumns[source] = cColumns.filter(
            (c) => c.name != "" && c.name != null && c.customFunction != "" && c.customFunction != null
          );
        }
      }
      this.setState({
        newCustomColumns: validCustomColumns,
      });
    }
    if (primaryKeys) {
      this.setState({
        primaryKeys: primaryKeys,
      });
      const test = JSON.parse(JSON.stringify(primaryKeys));
      const newsources = produce(this.state.resources, (d) => {
        return d.map((r) => {
          return {
            ...r,
            primaryKey: test[`${r.schema}.${r.name}`],
          };
        });
      });

      this.props.setSources(newsources, this.props.datasource[0].id);
    }
  };

  onRelationItemSelected(item) {
    this.setState({
      newRelationId: item.id,
      newRelationSourceA: item.sourceA,
      newRelationColumnA: item.columnA,
      newRelationOperator: item.operator,
      newRelationSourceB: item.sourceB,
      newRelationColumnB: item.columnB,
    });

    this.onSourceASelect({
      name: item.sourceA.split(".")[1],
      schema: item.sourceA.split(".")[0],
    });
    this.onSourceBSelect({
      name: item.sourceB.split(".")[1],
      schema: item.sourceB.split(".")[0],
    });
  }

  onRelationItemDeselected() {
    this.setState({
      newRelationId: null,
      newRelationSourceA: "",
      newRelationColumnA: "",
      newRelationOperator: "",
      newRelationSourceB: "",
      newRelationColumnB: "",
    });
  }

  onVariableItemSelected(item) {
    this.setState({
      newVariable: item.variableName,
      newVariableValues: item.variableValues,
    });
  }

  onVariableItemDeselected() {
    this.setState({
      newVariable: "",
      newVariableValues: "",
    });
  }

  onVariableItemDeleted(item) {
    this.props.removeVariable(item, this.props.datasource[0].id);
  }

  onMeasureEditorChange(newMeasures) {
    this.setState({
      measures: newMeasures,
    });
  }

  render() {
    return (
      <Wrapper>
        <div>
          <TopControls>
            <LeftArea>
              <DataSourceForm>
                <Input
                  placeholder="DataSource name"
                  value={this.state.newDatasourceName}
                  onChange={(e) => this.setState({ newDatasourceName: e.target.value })}
                />
                <Input
                  placeholder="DataSource description"
                  value={this.state.newDatasourceDescription}
                  onChange={(e) => this.setState({ newDatasourceDescription: e.target.value })}
                />
                <Input
                  placeholder="DataSource tags"
                  value={this.state.newDatasourceTags}
                  onChange={(e) => this.setState({ newDatasourceTags: e.target.value })}
                />
              </DataSourceForm>
              <DataSourceForm>
                <KSTKSelect
                  placeholder={<div>Organization</div>}
                  isMulti={false}
                  isClearable={true}
                  options={this.state.organizationList}
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.id}
                  onChange={(s) => this.setState({ selectedOrg: s })}
                  value={
                    this.state.selectedOrg != null
                      ? this.state.organizationList.filter((o) => o.id == this.state.selectedOrg.id)[0]
                      : null
                  }
                />
                <KSTKSelect
                  placeholder={<div>Module</div>}
                  isMulti={false}
                  isClearable={true}
                  options={this.state.moduleList}
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.id}
                  onChange={(m) => this.setState({ selectedModule: m })}
                  value={
                    this.state.selectedModule != null
                      ? this.state.moduleList.filter((o) => o.id == this.state.selectedModule.id)[0]
                      : null
                  }
                />
                {this.state.activeTab === "1" ? (
                  <div>
                    <Button onClick={this.fillFromOrganizationOrModule}>Generate</Button>
                  </div>
                ) : null}
              </DataSourceForm>
            </LeftArea>
            <RightArea>
              <Button onClick={this.updateDataSourceMetadata}>Save</Button>
            </RightArea>
          </TopControls>
        </div>
        <SourcesContent>
          <Nav tabs>
            <StyledNavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggleTabs("1");
                }}
              >
                Sources
              </NavLink>
            </StyledNavItem>
            <StyledNavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggleTabs("2");
                }}
              >
                Sources Table
              </NavLink>
            </StyledNavItem>
            <StyledNavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggleTabs("3");
                }}
              >
                Relations
              </NavLink>
            </StyledNavItem>
            <StyledNavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggleTabs("4");
                }}
              >
                Measures
              </NavLink>
            </StyledNavItem>
            <StyledNavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggleTabs("5");
                }}
              >
                Variables
              </NavLink>
            </StyledNavItem>
          </Nav>
          <StyledTabContent activeTab={this.state.activeTab}>
            <StyledTabPane tabId="1">
              <ItemListWrapper>
                {/* <div>
                  <DataSourceForm onSubmit={this.upsertSource}>
                    <Input
                      placeholder="Source name..."
                      value={this.state.newSourceName}
                      onChange={(e) =>
                        this.setState({ newSourceName: e.target.value })
                      }
                    />
                    <Input
                      placeholder="Source schema..."
                      value={this.state.newSourceSchema}
                      onChange={(e) =>
                        this.setState({ newSourceSchema: e.target.value })
                      }
                    />
                    <Button type="submit">Add/Edit</Button>
                  </DataSourceForm>
                </div> */}
                {this.state.filteredDatasourceResources != null && this.state.filteredDatasourceResources.length > 0 ? (
                  <div>
                    <KSTKItemList
                      noEdit={true}
                      columns={this.sourceColumns}
                      data={this.state.filteredDatasourceResources.sort(function (a, b) {
                        if (a["name"].localeCompare(b["name"], "pt") == -1) {
                          return -1;
                        }
                        if (a["name"].localeCompare(b["name"], "pt") == 1) {
                          return 1;
                        }
                      })}
                      onSelect={this.onSourceItemSelected}
                      onDeselect={this.onSourceItemDeselected}
                      onDelete={this.onSourceItemDeleted}
                    />
                  </div>
                ) : null}
              </ItemListWrapper>
            </StyledTabPane>
            <StyledTabPane tabId="2">
              {this.state.selectedOrg === null || this.state.selectedOrg === undefined ? (
                <KSTKEmptyContent message="Please choose an organization" icon="table" />
              ) : (
                <KSTKReportSourcesEditor
                  datasource={this.props.datasource[0]}
                  onChange={this.onSourcesEditorChange}
                  selected={this.state.selectedOrg}
                  selectedModule={this.state.selectedModule}
                />
              )}
            </StyledTabPane>
            <StyledTabPane tabId="3">
              <Wrapper>
                <div>
                  <DataSourceForm>
                    <KSTKDropdown
                      label="Source A..."
                      selected={this.state.newRelationSourceA}
                      entryLabel={this.tableEntryLabel}
                      data={this.state.filteredDatasourceResources}
                      onSelect={this.onSourceASelect}
                      ref={this.newRelationSourceARef}
                    />
                    <KSTKDropdown
                      label="Column A..."
                      selected={this.state.newRelationColumnA}
                      entryLabel={(c) => c.name}
                      data={this.state.availableColumnAValue}
                      onSelect={this.onColumnASelect}
                      ref={this.newRelationColumnARef}
                    />
                    <KSTKDropdown
                      label="Operator..."
                      selected={this.state.newRelationOperator}
                      entryLabel={(c) => c}
                      data={this.relationOperators}
                      onSelect={this.onOperatorsSelect}
                      ref={this.newRelationOperatorRef}
                    />
                    <KSTKDropdown
                      label="Source B..."
                      selected={this.state.newRelationSourceB}
                      entryLabel={this.tableEntryLabel}
                      data={this.state.filteredDatasourceResources}
                      onSelect={this.onSourceBSelect}
                      ref={this.newRelationSourceBRef}
                    />
                    <KSTKDropdown
                      label="Column B..."
                      selected={this.state.newRelationColumnB}
                      entryLabel={(c) => c.name}
                      data={this.state.availableColumnBValue}
                      onSelect={this.onColumnBSelect}
                      ref={this.newRelationColumnBRef}
                    />
                    <Button onClick={this.upsertRelation}>Add/Edit</Button>
                  </DataSourceForm>
                </div>
                <div>
                  <KSTKItemList
                    columns={this.relationColumns}
                    data={this.props.datasource[0].relations}
                    onSelect={this.onRelationItemSelected}
                    onDeselect={this.onRelationItemDeselected}
                    onDelete={this.onRelationItemDeleted}
                  />
                </div>
              </Wrapper>
            </StyledTabPane>
            <StyledTabPane tabId="4">
              <MeasuresEditorWrapper>
                <KSTKMeasureEditor
                  options={getColumnsFromDatasource(
                    this.getCurrentDatasourceRepresentation(),
                    this.state.measures
                  ).filter((r) => {
                    if (r.type == "COLUMN") {
                      return r.value.indexOf("qa_") != 0;
                    } else if (r.type == "MEASURE") {
                      return true;
                    }
                  })}
                  // report={this.state.report[0]}
                  // measures={this.state.measures}
                  // reports={this.props.reports.filter((rep) => rep.id !== this.state.report.id)}
                  onChange={this.onMeasureEditorChange}
                  value={this.state.measures}
                ></KSTKMeasureEditor>
              </MeasuresEditorWrapper>
            </StyledTabPane>
            <StyledTabPane tabId="5">
              <Wrapper>
                <div>
                  <VariablesWrapper>
                    <Input
                      type="text"
                      placeholder="Variable Name"
                      value={this.state.newVariable.replace("$$ ", "")}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          newVariable: `$$ ${e.target.value}`,
                        });
                      }}
                    />
                    <Input
                      type="text"
                      placeholder="Variable Values"
                      value={this.state.newVariableValues}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          newVariableValues: e.target.value,
                        });
                      }}
                    />
                    <Button onClick={this.upsertVariable}>Add/Edit</Button>
                  </VariablesWrapper>
                </div>
                <div>
                  <KSTKItemList
                    columns={this.variableColumns}
                    data={this.props.datasource[0].variables || []}
                    onSelect={this.onVariableItemSelected}
                    onDeselect={this.onVariableItemDeselected}
                    onDelete={this.onVariableItemDeleted}
                  />
                </div>
              </Wrapper>
            </StyledTabPane>
          </StyledTabContent>
        </SourcesContent>
      </Wrapper>
    );
  }
}
