import { generateService, BASE_URL, handleErrors } from "./Util";
import { fetchAuthentication } from "./Client";

const service = generateService("Containers");

export const createFiles = (containerId, files) => {
  return fetch(`${BASE_URL}/Containers/${containerId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  }).then((response) => {
    // Container does not exist
    if (response.status === 404) {
      fetch(`${BASE_URL}/Containers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
        body: JSON.stringify({ name: containerId }),
      }).then((response) => {
        // Container was created in the meantime
        if (response.status === 500) {
          uploadFilesHandler(containerId, files);
        } else {
          uploadFilesHandler(containerId, files);
        }
      });
    } else {
      uploadFilesHandler(containerId, files);
    }
  });
};

function uploadFilesHandler(containerId, files) {
  for (var i = 0; i < files.length; i++) {
    var fd = new FormData();

    fd.append("file", files[i]);
    fetch(`${BASE_URL}/Containers/${containerId}/upload`, {
      method: "POST",
      headers: {
        Authorization: fetchAuthentication().value.id,
      },
      body: fd,
    })
      .then(function (data) {})
      .catch(function (data) {});
  }
}

export const deleteFiles = (containerId, fileName) => {
  return fetch(`${BASE_URL}/Containers/${containerId}/files/${fileName}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  }).then((response) => {
    // Container does not exist
    if (response.status === 404) {
      return null;
    } else {
      return response;
    }
  });
};

export const getFilesFromContainer = (containerId, fileName) => {
  return fetch(`${BASE_URL}/Containers/${containerId}/download/${fileName}`, {
    method: "GET",
    headers: {
      Authorization: fetchAuthentication().value.id,
    },
  }).then((response) => {
    // Container does not exist
    if (response.status === 404) {
      return null;
    } else {
      return response;
    }
  });
};
