import React from "react";
import styled from "styled-components";

const StyledColorInput = styled.input`
  width: 50px !important;
`;

export function KSTKColorPicker(props) {
  return <StyledColorInput type="color" {...props}></StyledColorInput>;
}
