import React, { useContext } from "react";
import styled from "styled-components";
import { KSTKSelect } from "./KSTKSelect";
import { ColumnTypes } from "../util/report.util";

import type { ChartMetadataRowColumnAggregator } from "./KSTKComponentTypes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const KSTKRowAggregatorsSelect = styled(KSTKSelect)`
  & > div {
    height: 100% !important;
  }
`;

export function KSTKMultipleColumnFunctionFieldSidePanel(props) {
  const fieldOperations = props.onlySelectMode
    ? [
        { value: "sum", label: "Sum" },
        { value: "avg", label: "Average" },
        { value: "count", label: "Count" },
      ]
    : [
        { value: "sum", label: "Sum" },
        { value: "avg", label: "Average" },
        { value: "max", label: "Max" },
        { value: "min", label: "Min" },
      ];

  const getFieldValue = () => {
    if (props.value) {
      return props.options
        .filter((o) => o.type === ColumnTypes.COLUMN)
        .filter((o) => props.value.indexOf(o.value) != -1);
    }

    return [];
  };

  const getAggregatorValue = () => {
    if (props.operationValue) {
      return fieldOperations.filter((f) => f.value === props.operationValue);
    } else {
      return [];
    }
  };

  const handleFieldChange = (value) => {
    let changeValue: ChartMetadataRowColumnAggregator = {};
    let aggregator = getAggregatorValue();
    changeValue.field =
      value && value.length
        ? value.map((el) => {
            return el.value;
          })
        : "";
    changeValue.aggregator = aggregator && aggregator.length ? getAggregatorValue()[0].value : "";

    props.onChange(changeValue);
  };

  const handleAggregatorChange = (value) => {
    let changeValue: ChartMetadataRowColumnAggregator = {};
    let field = getFieldValue();
    changeValue.field =
      field && field.length
        ? field.map((el) => {
            return el.value;
          })
        : "";
    changeValue.aggregator = value && value.value ? value.value : "";

    props.onChange(changeValue);
  };

  return (
    <Wrapper>
      {props.onlySelectMode == null ? (
        <KSTKSelect
          id="label"
          placeholder="Aggregator"
          isMulti={false}
          isSearchable="false"
          isClearable="false"
          options={fieldOperations}
          onChange={(value) => handleAggregatorChange(value)}
          value={getAggregatorValue()}
          isDisabled={props.isDisabled}
        />
      ) : null}

      <KSTKRowAggregatorsSelect
        id="label"
        placeholder="Columns"
        isMulti={true}
        isSearchable="true"
        isClearable="false"
        options={props.options}
        onChange={(value) => handleFieldChange(value)}
        value={getFieldValue()}
        isDisabled={props.isDisabled}
      />
      {/* <i className="far fa-times-circle" onClick={props.onDelete} /> */}
    </Wrapper>
  );
}
