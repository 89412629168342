import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { fetchAuthentication } from "../services/Client";

export class ProtectedRoute extends Component {
    render() {
        const { component: Component, ...props } = this.props
        // console.log(props);
        return < Route
            {...props}
            render={
                props => (
                    fetchAuthentication() ?
                        <Component {...this.props} {...props} /> :
                        <Redirect to='/login' />
                )
            }
        />
    }
}

export const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
}

export const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => {
            return renderMergedProps(component, routeProps, rest);
        }} />
    );
}