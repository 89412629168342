import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { KSTKSelect } from "./KSTKSelect";
import { ColumnTypes } from "../util/report.util";
import { Input } from "reactstrap";
import { throttle } from "lodash";

import type { ChartMetadataValue } from "./KSTKComponentTypes";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
`;

export function KSTKValueFieldSidePanel(props) {
  const fieldOperations = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
    { value: "count", label: "Count" },
    { value: "count_dist", label: "Count (Distinct)" },
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
    { value: "distinct", label: "Distinct" },
  ];

  const dataTypeList = [
    /* { label: "Currency", value: "currency" }, */
    { label: "Date", value: "date" },
    { label: "Decimal", value: "decimal" },
    { label: "Percentage", value: "percentage" },
    { label: "(empty)", value: null },
  ];

  const [name, setName] = useState(props.seriesName ? props.seriesName : "");
  const [decimalPlaces, setDecimalPlaces] = useState(props.decimalPlaces ? props.decimalPlaces : "");

  useEffect(() => {
    setName(props.seriesName ? props.seriesName : "");
    setDecimalPlaces(props.decimalPlaces ? props.decimalPlaces : "");
  }, [props.decimalPlaces, props.seriesNameField]);

  const notify = throttle(props.onChange, 5000);

  const getFieldValue = () => {
    if (props.value) {
      switch (typeof props.value) {
        case "string":
          return props.options.filter((o) => o.type === ColumnTypes.COLUMN).filter((o) => o.value === props.value);
          break;
        case "object":
          if (props.value?.variableName != null) {
            return props.options
              .filter((o) => o.type === ColumnTypes.VARIABLE)
              .filter((o) => o.value.variableName === props.value.variableName);
          } else {
            return props.options
              .filter((o) => o.type === ColumnTypes.MEASURE)
              .filter((o) => o.value.id === props.value.id);
          }
          break;
      }
    }

    return [];
  };

  const getAggregatorValue = () => {
    if (props.operationValue) {
      return fieldOperations.filter((f) => f.value === props.operationValue);
    } else {
      return [];
    }
  };

  const getDataTypeValue = () => {
    if (props.dataTypeValue) {
      return dataTypeList.filter((d) => d.value === props.dataTypeValue);
    } else {
      return [];
    }
  };

  const handleAggregatorChange = (value) => {
    let changeValue: ChartMetadataValue = getCurrentValues();
    changeValue.aggregator = value && value.value ? value.value : "";

    props.onChange(changeValue);
  };

  const handleFieldChange = (value) => {
    let changeValue: ChartMetadataValue = getCurrentValues();
    changeValue.field = value && value.value ? value.value : "";

    props.onChange(changeValue);
  };

  const handleSeriesNameChange = (newName) => {
    setName(newName);
    let changeValue: ChartMetadataValue = getCurrentValues();
    changeValue.seriesName = newName && newName != "" ? newName : "";

    notify(changeValue);
  };

  const handleDataTypeChange = (newDataType) => {
    let changeValue: ChartMetadataValue = getCurrentValues();
    changeValue.dataType = newDataType && newDataType.value ? newDataType.value : "";

    props.onChange(changeValue);
  };

  const handleSeriesDecimalPlacesChange = (newSeriesDecimalPlaces) => {
    setDecimalPlaces(newSeriesDecimalPlaces);
    let changeValue = getCurrentValues();
    changeValue.decimalPlaces = newSeriesDecimalPlaces ? newSeriesDecimalPlaces : "";

    notify(changeValue);
  };

  const getCurrentValues = () => {
    let changeValue: ChartMetadataValue = {};
    let aggregator = getAggregatorValue();
    let field = getFieldValue();
    let dataType = getDataTypeValue();
    changeValue.field = field && field.length ? field[0].value : "";
    changeValue.aggregator = aggregator && aggregator.length ? aggregator[0].value : "";
    changeValue.seriesName = name;
    changeValue.dataType = dataType && dataType.length ? dataType[0].value : "";
    changeValue.decimalPlaces = decimalPlaces;

    return changeValue;
  };

  return (
    <Wrapper>
      <KSTKSelect
        id="label"
        placeholder="Aggregator"
        isMulti={false}
        isSearchable="false"
        isClearable="false"
        options={fieldOperations}
        onChange={(value) => handleAggregatorChange(value)}
        value={getAggregatorValue()}
        isDisabled={props.isDisabled}
      />
      <KSTKSelect
        id="label"
        placeholder="Column"
        isMulti={false}
        isSearchable="true"
        isClearable="false"
        options={props.options}
        onChange={(value) => handleFieldChange(value)}
        value={getFieldValue()}
        isDisabled={props.isDisabled}
      />
      {props.seriesNameField == null || props.seriesNameField == true ? (
        <Input placeholder="Series custom name" onChange={(e) => handleSeriesNameChange(e.target.value)} value={name} />
      ) : null}
      {props.seriesDataType == null || props.seriesDataType == true ? (
        <KSTKSelect
          id="dataType"
          placeholder="Data Type"
          isMulti={false}
          isSearchable={true}
          isClearable={false}
          options={dataTypeList}
          onChange={(value) => handleDataTypeChange(value)}
          value={getDataTypeValue()}
          isDisabled={props.isDisabled}
        />
      ) : null}
      {props.seriesDecimalPlaces == null || props.seriesDecimalPlaces == true ? (
        <Input
          placeholder="Decimal Places"
          onChange={(e) => handleSeriesDecimalPlacesChange(e.target.value)}
          value={decimalPlaces}
        />
      ) : null}
    </Wrapper>
  );
}
