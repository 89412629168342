import React, { Component } from "react";
import styled from "styled-components";
import { Input, Card, CardBody, CardText, Button } from "reactstrap";
import { KSTKReadOnlyField } from "./KSTKBaseComponents";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const CardItem = styled(Card)`
  height: 65px;
  margin: 10px 0 10px 0;

  &.selected {
    border: 1px solid blue;
  }
`;

const CardBodyItem = styled(CardBody)`
  padding: 10px !important;
`;

const CardTextItem = styled(CardText)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const CardTextItemChild = styled(KSTKReadOnlyField)`
  width: 150px;
`;

const ItemListSearch = styled(Input)`
  width: 150px;
`;

export default class KSTKItemList extends Component {
  constructor(props) {
    super(props);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.state = { selectedIndex: null };
  }

  toggleSelection(event, data, index) {
    if (this.props.noEdit) {
      return;
    }

    if (this.state.selectedIndex === index) {
      this.setState({ selectedIndex: null });
      if (this.props.onDeselect) {
        this.props.onDeselect(data);
      }
    } else {
      this.setState({ selectedIndex: index });
      if (this.props.onSelect) {
        this.props.onSelect(data);
      }
    }
  }

  onDelete(e, d) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.props.onDelete(d);
  }

  render() {
    return (
      <Wrapper>
        {this.props.title ? <h3>{this.props.title}</h3> : null}
        {this.props.searchFunction ? (
          <ItemListSearch
            name="search"
            id="search"
            placeholder={"Search..."}
            onChange={(e) => this.props.searchFunction(e)}
          />
        ) : null}
        {this.props.data.map((d, i) => {
          return (
            <CardItem
              key={i}
              onClick={(e) => this.toggleSelection(e, d, i)}
              className={this.state.selectedIndex === i ? "selected" : ""}
            >
              <CardBodyItem>
                <CardTextItem>
                  {this.props.columns.map((c, ci) => {
                    return (
                      <CardTextItemChild
                        key={ci * 1000}
                        label={c.name}
                        value={typeof d[c.key] === "string" ? d[c.key] : d[c.key]?.name}
                      />
                    );
                  })}
                  <Button onClick={(e) => this.onDelete(e, d)}>Delete</Button>
                </CardTextItem>
              </CardBodyItem>
            </CardItem>
          );
        })}
      </Wrapper>
    );
  }
}
