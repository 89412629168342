import { fetchAuthentication, invalidateAuth } from "./Client";

export const BASE_URL = "/api";

// Handle HTTP errors since fetch won't.
export function handleErrors(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      if (response.status === 401) {
        invalidateAuth();
      }

      return Promise.reject(json);
    }
    return json;

    // if (!response.ok) {
    //   if (response.status === 401) {
    //     invalidateAuth();
    //   }
    //   if (response.status > 400 && response.status <= 500) {
    //     throw Error(response.statusText);
    //   }
    // }
    // return response;
  });
}

export function generateService(entity, generateNewEntity) {
  return {
    get: () => {
      return fetch(`${BASE_URL}/${entity}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then(handleErrors);
    },
    getById: (id) => {
      return fetch(`${BASE_URL}/${entity}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then(handleErrors);
    },
    put: (obj) => {
      return fetch(`${BASE_URL}/${entity}/${obj.id}`, {
        method: "PUT",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then(handleErrors);
    },
    post: (obj) => {
      return fetch(`${BASE_URL}/${entity}`, {
        method: "POST",
        body: JSON.stringify(obj ? obj : generateNewEntity()),
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then(handleErrors);
    },
    delete: (id) => {
      return fetch(`${BASE_URL}/${entity}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then(handleErrors);
    },
  };
}
