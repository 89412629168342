// @flow
import { generateService, BASE_URL, handleErrors } from "./Util";
import { fetchAuthentication } from "./Client";

import type { ChartDataQueryParams, ChartMetadata, ChartJSModel } from "../components/KSTKComponentTypes";

export const generateReport = () => {
  return {
    name: "Untitled report...",
    description: "",
    tags: "",
    customHeaders: [],
    customModelations: [],
    createdAt: new Date().getTime(),
  };
};

const service = generateService("KSTKReportingReports", generateReport);

export const ORG = "ORG";
export const USER = "USER";
export const REP_FILTERS = ["", ORG, USER];

export const getReports = service.get;
export const getReportById = service.getById;
export const createReport = service.post;
export const updateReport = service.put;
export const deleteReport = service.delete;

let chartDataCache = {};

const getChartDataFromCache = (orgId: string, metadata: ChartDataQueryParams, serverside) => {
  const key = `${orgId}-${JSON.stringify(metadata)}-${JSON.stringify(serverside)}`;
  if (chartDataCache[key] && chartDataCache[key].timestamp < Date.now() - 1000 * 60 * 5) {
    console.log("cleaning cache ⚠️");
    chartDataCache[key] = null;
  }
  return chartDataCache[key];
};

const setChartDataCache = (orgId, metadata, data, serverside) => {
  console.log("setting the cache 💾");
  const key = `${orgId}-${JSON.stringify(metadata)}-${JSON.stringify(serverside)}`;
  chartDataCache[key] = {
    data,
    timestamp: Date.now(),
  };
};

export const getChartData = (orgId: string, metadata: ChartDataQueryParams, serverside = { skip: 0, limit: 1000 }) => {
  const { skip, limit } = serverside;
  const cachedData = getChartDataFromCache(orgId, metadata, serverside);
  if (cachedData) {
    console.log("cache hit ✅");
    return Promise.resolve(cachedData.data);
  }
  return fetch(`${BASE_URL}/KSTKReportingReports/getChartData/${orgId}?skip=${skip}&limit=${limit}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
    body: JSON.stringify({ metadata: metadata }),
  })
    .then(handleErrors)
    .then((data) => {
      setChartDataCache(orgId, metadata, data, serverside);
      return data;
    });
};

export const exportTableData = (orgId: string, metadata: ChartDataQueryParams, datasource) => {
  return fetch(`${BASE_URL}/KSTKReportingReports/exportTableData/${orgId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
    body: JSON.stringify({ metadata: metadata, datasource: datasource }),
  })
    .then(handleErrors)
    .then((data) => {
      return data;
    });
};

export const publishReport = (orgId, datasource) => {
  return fetch(`${BASE_URL}/KSTKReportingReports/publishReport/${orgId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
    body: JSON.stringify({ datasource: datasource }),
  })
    .then(handleErrors)
    .then((check) => {
      return check;
    });
};

// export const clearChartDataCache = () => {
//   chartDataCache = {};
// };

export const clearChartDataCache = (orgId: string, metadata: ChartDataQueryParams, serverside) => {
  const keys = Object.keys(chartDataCache).filter((k) => k.indexOf(`${orgId}-${JSON.stringify(metadata)}`) !== -1);
  //const key = `${orgId}-${JSON.stringify(metadata)}-${JSON.stringify(serverside)}`;
  keys.forEach((key) => {
    chartDataCache[key] = null;
  });
};

export const isChartDataCached = (orgId: string, metadata: ChartDataQueryParams, serverside) => {
  const key = `${orgId}-${JSON.stringify(metadata)}-${JSON.stringify(serverside)}`;
  return !!chartDataCache[key];
};

export const logReportPageView = (page) => {
  return fetch(`${BASE_URL}/Logs/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
    body: JSON.stringify({
      who: fetchAuthentication().value.user.email,
      organization: fetchAuthentication().value.user.organization,
      type: "report page view",
      details: page,
      date: new Date().toISOString(),
    }),
  }).then(handleErrors);
};

// const originalGetChartData = (orgId: string, metadata: ChartDataQueryParams) => {
//   return fetch(`${BASE_URL}/KSTKReportingReports/getChartData/${orgId}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: fetchAuthentication().value.id,
//     },
//     body: JSON.stringify({ metadata: metadata }),
//   }).then(handleErrors);
// };

export const clearOrgDataCache = (orgId, dataMode) => {
  return fetch(`${BASE_URL}/KSTKReportingReports/clearDataCache/${orgId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
    body: JSON.stringify({ dataMode: dataMode }),
  })
    .then(handleErrors)
    .then((check) => {
      return check;
    });
};
