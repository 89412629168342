//@flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, ButtonGroup, Button } from "reactstrap";
import { v1 as uuid } from "uuid";
import produce from "immer";
import { KSTKSelect } from "./KSTKSelect";

const Wrapper = styled.div``;

const FilterFieldsWrapper = styled.div`
  ${({ mode }) =>
    mode != "visualsSidePanel" &&
    `
    display: grid;
    grid-template-columns: 20% 20% 20% 5% 10% 20%;
    grid-column-gap: 1%;

    & #logicalOperation {
      grid-column-start: 6;
    }
  `}

  ${({ mode }) =>
    mode == "visualsSidePanel" &&
    `
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;
  `}
  margin: 5px 0 5px 0;
`;

const RemoveValueButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type KSTKFilterValues = {
  id: string,
  column: string,
  operation: string,
  value: string,
  logicOperation: string,
  filters: [],
};

export type KSTKFilterFieldProps = {
  options: Array<Object>,
  values: Array<Object>,
  isDisabled: boolean,
  onChange: Function,
};

export default function KSTKFilterField(props: KSTKFilterFieldProps) {
  const fieldOperations = [
    { value: "=", label: "=" },
    { value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
    { value: "<>", label: "!=" },
    { value: "like", label: "Text is exactly" },
    { value: "not like", label: "Text is not" },
    { value: "notBlank", label: "Is not blank" },
  ];

  const logicOperations = [
    { value: "and", label: "and" },
    { value: "or", label: "or" },
  ];

  const createFieldValue = () => {
    return {
      id: uuid(),
      column: "",
      operation: "",
      value: "",
      logicOperation: "",
      filters: [],
      variable: "",
    };
  };

  const generateInitialValues = (_) => {
    if (props.values && props.values.length) {
      // const lastValue = props.values.slice(-1)[0];

      // if (lastValue && lastValue.logicOperation != "") {
      //   return props.values.concat(createFieldValue());
      // } else {
      //   return props.values;
      // }
      return props.values;
    } else {
      return [createFieldValue()];
    }
  };

  const notifyChanges = (values) => {
    if (props.onChange) {
      // props.onChange(values.slice(0, values.length - 1));
      props.onChange(values);
    }
  };

  let [values, setValues] = useState(generateInitialValues());

  // useEffect(
  //   _ => {
  //     if (props.onChange) {
  //       props.onChange(values.slice(0, values.length - 1));
  //     }
  //   },
  //   [values]
  // );

  useEffect(
    (_) => {
      // console.log(props.values);
      setValues(generateInitialValues());
    },
    [props.values]
  );

  const handleFieldChange = (id, field) => {
    let newValues = values.map((v) =>
      produce(v, (tempValue: KSTKFilterValues): void => {
        if (id === tempValue.id) {
          tempValue.column = field ? field.value : "";
        }
      })
    );

    // setValues(newValues);
    notifyChanges(newValues);
  };
  const getFieldValue = (id) => {
    const foundValue: ?KSTKFilterValues = values.find((v) => v.id === id);

    if (foundValue && props.options) {
      if (typeof foundValue.column === "object") {
        return props.options.filter((o) => o.value.name === foundValue.column.name);
      } else {
        return props.options.filter((o) => o.value === foundValue.column);
      }
    } else {
      return [];
    }
  };

  const handleOperationChange = (id, operation) => {
    let newValues = values.map((v) =>
      produce(v, (tempValue: KSTKFilterValues): void => {
        if (id === tempValue.id) {
          tempValue.operation = operation ? operation.value : "";
          if (operation.value == "notBlank") {
            tempValue.value = "";
          }
        }
      })
    );

    // setValues(newValues);
    notifyChanges(newValues);
  };
  const getOperationValue = (id) => {
    const foundValue: ?KSTKFilterValues = values.find((v) => v.id === id);

    if (foundValue) {
      return fieldOperations.filter((fo) => fo.value === foundValue.operation);
    } else {
      return [];
    }
  };

  const handleValueChange = (id, value) => {
    let newValues = values.map((v) =>
      produce(v, (tempValue: KSTKFilterValues): void => {
        if (id === tempValue.id) {
          tempValue.value = value ? value : "";
        }
      })
    );

    // setValues(newValues);
    notifyChanges(newValues);
  };
  const getValueValue = (id) => {
    const foundValue: ?KSTKFilterValues = values.find((v) => v.id === id);

    if (foundValue) {
      return foundValue.value;
    } else {
      return "";
    }
  };

  const handleVariableChange = (id, field) => {
    let newValues = values.map((v) =>
      produce(v, (tempValue: KSTKFilterValues): void => {
        if (id === tempValue.id) {
          tempValue.variable = field ? field.value : "";
        }
      })
    );

    // setValues(newValues);
    notifyChanges(newValues);
  };
  const getVariableValue = (id) => {
    const foundValue: ?KSTKFilterValues = values.find((v) => v.id === id);

    if (foundValue && props.options) {
      if (typeof foundValue.variable === "object") {
        return props.options.filter((o) => o.value.variableName === foundValue.variable.variableName);
      }
    } else {
      return [];
    }
  };

  const handleLogicalOperationChange = (id, operation) => {
    const index = values.findIndex((v) => v.id === id);

    let newValues = values.map((v) =>
      produce(v, (tempValue: KSTKFilterValues): void => {
        if (id === tempValue.id) {
          tempValue.logicOperation = operation ? operation : "";
        }
      })
    );

    if (operation && index === values.length - 1) {
      newValues = produce(newValues, (tempNewValues) => {
        tempNewValues.splice(values.findIndex((v) => v.id === id) + 1, 0, createFieldValue());
      });
    }

    // setValues(newValues);
    notifyChanges(newValues);
  };
  const getLogicalOperationValue = (id, logicOperation) => {
    const foundValue = values.find((v) => v.id === id);

    if (foundValue.logicOperation === logicOperation) {
      return true;
    } else {
      return false;
    }
  };

  const onFieldDelete = (id) => {
    const index = values.findIndex((v) => v.id === id);
    // if (index === values.length - 1) {
    //   return;
    // }

    const newValues = produce(values, (tempValues) => {
      tempValues.splice(index, 1);
      if (tempValues.length && tempValues[tempValues.length - 1]?.logicOperation !== "") {
        tempValues[tempValues.length - 1].logicOperation = "";
      }
    });
    // setValues(newValues);
    notifyChanges(newValues);
  };

  return (
    <Wrapper>
      {values.map((v, i) => (
        <FilterFieldsWrapper mode={props.mode} key={i}>
          <KSTKSelect
            id="column"
            placeholder="Column"
            isMulti={false}
            isSearchable="true"
            isClearable={true}
            options={props.options}
            onChange={(value) => handleFieldChange(v.id, value)}
            value={getFieldValue(v.id)}
            isDisabled={props.isDisabled}
          />
          <KSTKSelect
            id="operation"
            placeholder="Operation"
            isMulti={false}
            isSearchable="false"
            isClearable={false}
            options={fieldOperations}
            onChange={(value) => handleOperationChange(v.id, value)}
            value={getOperationValue(v.id)}
            isDisabled={props.isDisabled}
          />
          {v.operation != "notBlank" && !props.variableValue ? (
            <Input
              id="value"
              placeholder="Value"
              disabled={props.isDisabled}
              onChange={(e) => handleValueChange(v.id, e.target.value)}
              value={getValueValue(v.id)}
            ></Input>
          ) : null}
          {v.operation != "notBlank" && props.variableValue ? (
            <KSTKSelect
              id="variable"
              placeholder="Variable"
              isMulti={false}
              isSearchable="true"
              isClearable={true}
              options={props.options.filter((o) => o.type === "VARIABLE")}
              onChange={(value) => handleVariableChange(v.id, value)}
              value={getVariableValue(v.id)}
              isDisabled={props.isDisabled}
            />
          ) : null}

          <RemoveValueButtonWrapper>
            <i className="far fa-times-circle" onClick={(e) => onFieldDelete(v.id)} />
          </RemoveValueButtonWrapper>
          <ButtonGroup id="logicalOperation">
            <Button
              color="secondary"
              active={getLogicalOperationValue(v.id, "and")}
              onClick={() => handleLogicalOperationChange(v.id, "and")}
            >
              And
            </Button>
            <Button
              color="secondary"
              active={getLogicalOperationValue(v.id, "or")}
              onClick={() => handleLogicalOperationChange(v.id, "or")}
            >
              Or
            </Button>
          </ButtonGroup>
        </FilterFieldsWrapper>
      ))}
    </Wrapper>
  );
}
