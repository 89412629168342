"use strict";
export const maps = {
  vfx: {
    mapboxSource: "mapbox://pedroveigakstk.1m8tnyth",
    latitude: 38.932,
    longitude: -9.03,
    zoom: 10,
    pointField: "AE / ENA",
    dataField: "matriculas.agrupamento",
    features: [
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE de Vialonga",
        },
        geometry: {
          coordinates: [-9.095936, 38.888511],
          type: "Point",
        },
        id: "32c732474dcd4b7b50da416c736d86c4",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE D. António de Ataíde",
        },
        geometry: {
          coordinates: [-8.973057, 38.990577],
          type: "Point",
        },
        id: "54a12d2dfe1b6c165fa1b03832f87714",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE Póvoa de Santa Iria",
        },
        geometry: {
          coordinates: [-9.069095, 38.858069],
          type: "Point",
        },
        id: "5ef887dbb0518a3b517d3bb08bfb56c4",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "ES Gago Coutinho",
        },
        geometry: {
          coordinates: [-9.037012, 38.900375],
          type: "Point",
        },
        id: "67385f5086ae25381589d0108788a69c",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE Alves Redol",
        },
        geometry: {
          coordinates: [-8.990951, 38.963786],
          type: "Point",
        },
        id: "79f7fc904314a0e605d40f38f7e0c241",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE de Forte da Casa",
        },
        geometry: {
          coordinates: [-9.05, 38.87097],
          type: "Point",
        },
        id: "7ef18636792e6819a6c6d41e177b3f7c",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE Pedro Jacques de Magalhães",
        },
        geometry: {
          coordinates: [-9.032704, 38.884642],
          type: "Point",
        },
        id: "a8c2f7b7fc81a1e6b5604c310cf97c5b",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE do Bom Sucesso",
        },
        geometry: {
          coordinates: [-9.055237, 38.917136],
          type: "Point",
        },
        id: "d0b6839c56ad80ac5a349f192f7b217c",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE Professor Reynaldo dos Santos",
        },
        geometry: {
          coordinates: [-9.006857, 38.950387],
          type: "Point",
        },
        id: "d378b3980be7e70715db1e97a48dd4ec",
      },
      {
        type: "Feature",
        properties: {
          "AE / ENA": "AE Alhandra, Sobralinho e São João dos Montes",
        },
        geometry: {
          coordinates: [-9.020053, 38.933377],
          type: "Point",
        },
        id: "ee6db77c39e6a48da9156304f6fcc716",
      },
    ],
    type: "FeatureCollection",
  },
  cimbal: {
    mapboxSource: "mapbox://pedroveigakstk.c8l3ecze",
    latitude: 37.841214,
    longitude: -7.7,
    zoom: 8,
    pointField: "Município Morada",
    dataField: "matriculas.municipio",
    features: [
      {
        type: "Feature",
        properties: {
          "Município Morada": "Ferreira do Alentejo",
        },
        geometry: {
          coordinates: [-8.255226, 38.06],
          type: "Point",
        },
        id: "1444dfebc170324d0158b52dabd4e939",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Cuba",
        },
        geometry: {
          coordinates: [-7.941578, 38.138399],
          type: "Point",
        },
        id: "2cdebd778d271712bf87d037fa3883d3",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Moura",
        },
        geometry: {
          coordinates: [-7.407337, 38.123117],
          type: "Point",
        },
        id: "3b40951edeb4b54646ea2b01b9dcd475",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Almodôvar",
        },
        geometry: {
          coordinates: [-8.09981, 37.478418],
          type: "Point",
        },
        id: "46ecbb25eeb1e94f42b064f0d9763c93",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Barrancos",
        },
        geometry: {
          coordinates: [-7.064936, 38.153677],
          type: "Point",
        },
        id: "4b4151b8795323715bddce1f43087d47",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Vidigueira",
        },
        geometry: {
          coordinates: [-7.720597, 38.165134],
          type: "Point",
        },
        id: "4d42a566f7cf6996059308a965180b44",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Serpa",
        },
        geometry: {
          coordinates: [-7.526327, 37.928],
          type: "Point",
        },
        id: "5a174117f3ee84bfbe540faa022a1359",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Beja",
        },
        geometry: {
          coordinates: [-7.859014, 37.966299],
          type: "Point",
        },
        id: "6bdbbdc4ebe3337f745cd28e07e4c7b2",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Mértola",
        },
        geometry: {
          coordinates: [-7.699128, 37.630505],
          type: "Point",
        },
        id: "87a2933b27ebb99bcdf7193b9bece73a",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Aljustrel",
        },
        geometry: {
          coordinates: [-8.206658, 37.885848],
          type: "Point",
        },
        id: "b3b3bb4a86d1b18a026af56812a0ecfe",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Ourique",
        },
        geometry: {
          coordinates: [-8.243084, 37.638197],
          type: "Point",
        },
        id: "ed6f9cddc531236cc1f4b3e240502345",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Alvito",
        },
        geometry: {
          coordinates: [-8.046386, 38.271974],
          type: "Point",
        },
        id: "f64c290f33c1160f2680624b47e58902",
      },
      {
        type: "Feature",
        properties: {
          "Município Morada": "Castro Verde",
        },
        geometry: {
          coordinates: [-8.034244, 37.713156],
          type: "Point",
        },
        id: "fa5b9d653857aa2c65fc64c0ae42e870",
      },
    ],
    type: "FeatureCollection",
  },
};
