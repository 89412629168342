import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  /* display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 40% 20% 40%; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Left = styled.div`
  overflow: hidden;
  height: 100%;
`;

const Right = styled.div`
  width: 100%;
  height: 100%;
`;

const Divider = styled.div`
  width: 2px;
  height: 100%;
  margin: 1rem;
  border: 2px solid #808080;
  cursor: col-resize;
`;

export function KSTKSplitView({ left, right, padding = 0, leftRatio = 0.75 }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isDragging, _setIsDragging] = useState(false);
  const [leftWidth, setLeftWidth] = useState(0);
  const isDraggingRef = useRef(isDragging);
  const setIsDragging = (data) => {
    isDraggingRef.current = data;
    _setIsDragging(data);
  };
  let leftComponentRef = createRef();

  const mouseMoveHandler = (e) => {
    if (isDraggingRef.current) {
      setMousePosition({ x: e.clientX - padding, y: e.clientY });
      // console.log(e);
    }
  };

  const mouseUpHandler = (e) => {
    setIsDragging(false);
  };

  const mouseDownHandler = (e) => {
    setIsDragging(true);
  };

  useEffect(() => {
    window.addEventListener("mousemove", mouseMoveHandler);
    window.addEventListener("mouseup", mouseUpHandler);

    return () => {
      window.removeEventListener("mouseup", mouseUpHandler);
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return (
    <Wrapper>
      <Left ref={leftComponentRef} style={{ minWidth: mousePosition?.x || visualViewport.width * leftRatio }}>
        {left(mousePosition?.x || visualViewport.width * leftRatio)}
      </Left>
      <Divider onMouseDown={mouseDownHandler}></Divider>
      <Right>{right}</Right>
    </Wrapper>
  );
}
