import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";
const Wrapper = styled.div`
  height: 100%;
`;

const ImageEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export function KSTKImage(props) {
  /* const getImageBaseUrl = () => {
    if (window.location.host.indexOf("localhost") > -1) {
      return "localhost:3000";
    }

    return "analytics.kstk.pt";
  }; */
  const [imageLoader, setImageLoader] = useState(true);

  useEffect(() => {
    if (props.metadata != null && (props.metadata.imageFile != null || props.metadata.imageUrl != null)) {
      setTimeout(() => {
        setImageLoader(false);
      }, 1000);
    }
  }, [props.metadata?.imageFile, props.metadata?.imageUrl]);

  const getImageUrl = () => {
    if (props.metadata.imageFile != null) {
      return "/api/containers/" + props.index + "image/download/" + props.metadata.imageFile;
    } else if (props.metadata.imageUrl != null) {
      return props.metadata.imageUrl;
    } else {
      return null;
    }
  };

  return (
    <Wrapper>
      {props.metadata != null &&
      (props.metadata.imageFile != null || props.metadata.imageUrl != null) &&
      !imageLoader ? (
        <Image style={{ backgroundImage: "url(" + getImageUrl() + ")" }}></Image>
      ) : null}
    </Wrapper>
  );
}
