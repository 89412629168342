import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Button, UncontrolledTooltip } from "reactstrap";
import { KSTKColorPicker } from "./KSTKColorPicker";
import produce from "immer";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 30px;

  width: 320px !important;
  & label {
    margin: 0 10px 0 10px;
  }
`;

const AlphaInput = styled(Input)`
  width: 80px !important;
`;

export function KSTKColorPalettePicker(props) {
  let [colorPickersValue, setColorPickersValue] = useState(props.colorPalette);

  useEffect(() => {
    if (JSON.stringify(props.colorPalette) != JSON.stringify(colorPickersValue)) {
      setColorPickersValue(props.colorPalette);
    }
  }, [props.colorPalette]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(colorPickersValue);
    }
  }, [colorPickersValue]);

  function onColorPickerChange(e) {
    return function (colorPickerIndex) {
      const newColors = produce(colorPickersValue, (c) => {
        c.splice(colorPickerIndex, 1, e.target.value);
      });
      setColorPickersValue(newColors);
    };
  }

  function getColorAtIndex(index) {
    return colorPickersValue[index];
  }

  function getColorsAplha() {
    return colorPickersValue[7];
  }

  function resetColorPickerValue() {
    return setColorPickersValue(["#36A2EB", "#FF6384", "#4BC0C0", "#FF9F40", "#9966FF", "#FFCD56", "#C9CBCF", "0.6"]);
  }

  return (
    <Wrapper>
      <KSTKColorPicker value={colorPickersValue[0]} onChange={(e) => onColorPickerChange(e)(0)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[1]} onChange={(e) => onColorPickerChange(e)(1)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[2]} onChange={(e) => onColorPickerChange(e)(2)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[3]} onChange={(e) => onColorPickerChange(e)(3)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[4]} onChange={(e) => onColorPickerChange(e)(4)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[5]} onChange={(e) => onColorPickerChange(e)(5)}></KSTKColorPicker>
      <KSTKColorPicker value={colorPickersValue[6]} onChange={(e) => onColorPickerChange(e)(6)}></KSTKColorPicker>
      <AlphaInput
        placeholder="Alpha"
        type="number"
        step="0.1"
        max="1"
        min="0.1"
        onChange={(e) => onColorPickerChange(e)(7)}
        value={getColorsAplha()}
      />
      <Button id="colorPickerValueReset" onClick={() => resetColorPickerValue()}>
        <i className="fa-solid fa-arrows-rotate" />
      </Button>
      <UncontrolledTooltip target={"colorPickerValueReset"}>{"Reset color palette"}</UncontrolledTooltip>
    </Wrapper>
  );
}
