import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";

import { KSTKSelect } from "./KSTKSelect";
import KSTKMeasureField from "./KSTKMeasureField";
import KSTKItemList from "./KSTKItemList";
import produce from "immer";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 350px 350px;
  grid-row-gap: 5%;
  height: 100%;
  width: 100%;
`;

const MeasureImporterTitle = styled.h5`
  grid-column-start: span 5;
`;

const MeasureImporterForm = styled.div`
  display: grid;
  grid-template-rows: 40px 60px;
  grid-template-columns: calc(95% - 80px) 80px;
  grid-column-gap: 5%;
`;

const MeasureForm = styled.div`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: calc(95% - 80px) 80px;
  grid-column-gap: 5%;
`;

const MeasureFormButtonWrapper = styled.div`
  justify-self: end;
  align-self: start;
`;

const MeasureItemList = styled(KSTKItemList)`
  overflow: auto;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export default function KSTKMeasureEditor(props) {
  const measureColumns = [
    { key: "name", name: "Name" },
    { key: "aggregateA", name: "Aggregate A" },
    { key: "columnA", name: "Column A" },
    { key: "operation", name: "Operation" },
    { key: "aggregateB", name: "Aggregate B" },
    { key: "columnB", name: "Column B" },
  ];

  const measurePrefix = "## ";

  const measureAddEdit = (_) => {
    if (measureFieldValue != null) {
      const exists = Boolean(measureList.find((m) => m.id === measureFieldValue.id));

      if (exists) {
        const newMeasureList = measureList.map((m) => {
          return produce(m, (tempMeasure) => {
            if (tempMeasure.id === measureFieldValue.id) {
              tempMeasure.name =
                measureFieldValue?.name?.indexOf(measurePrefix) >= 0
                  ? measureFieldValue.name
                  : measurePrefix + measureFieldValue.name;
              tempMeasure.aggregateA = measureFieldValue.aggregateA;
              tempMeasure.columnA = measureFieldValue.columnA;
              tempMeasure.operation = measureFieldValue.operation;
              tempMeasure.aggregateB = measureFieldValue.aggregateB;
              tempMeasure.columnB = measureFieldValue.columnB;
              tempMeasure.filters = measureFieldValue.filters;
              tempMeasure.allExcept = measureFieldValue.allExcept;
            }
          });
        });

        notifyChanges(newMeasureList);
        // setMeasureFieldValue(null);
      } else {
        notifyChanges([
          ...measureList,
          {
            ...measureFieldValue,
            name: measurePrefix + measureFieldValue.name,
          },
        ]);
        // setMeasureFieldValue(null);
      }
      setMeasureFieldValue(null);
      // measureField.current.resetField();
    }

    return false;
  };

  const createMeasure = (_) => {
    return {
      name: "",
      aggregateA: "",
      columnA: "",
      operation: "",
      aggregateB: "",
      columnB: "",
      filters: null,
      allExcept: null,
    };
  };

  const onMeasureSelect = (measure) => {
    let noHashesMeasure = {
      ...measure,
      name: measure?.name?.indexOf(measurePrefix) === 0 ? measure.name.substring(3) : measure.name,
    };

    setMeasureFieldValue(noHashesMeasure);
  };

  const onMeasureDeselect = (_) => {
    setMeasureFieldValue(createMeasure());
  };

  const onMeasureDelete = (measure) => {
    const elementToRemoveIndex = measureList.findIndex((m) => {
      return m.id === measure.id;
    });
    notifyChanges(measureList.filter((_, i) => i !== elementToRemoveIndex));
    setMeasureFieldValue(null);
  };

  let [measureFieldValue, setMeasureFieldValue] = useState(createMeasure());
  let [measureList, setMeasureList] = useState(props.value ? props.value : []);
  let [reportList, setReportList] = useState([]);
  let [filteredMeasureList, setFilteredMeasureList] = useState(props.value ? props.value : []);
  // let [reportsToImportMeasures, setReportsToImportMeasures] = useState([]);

  useEffect(
    (_) => {
      if (props.value) {
        props.value.sort(function (a, b) {
          if (a["name"] < b["name"]) {
            return -1;
          }
          if (a["name"] > b["name"]) {
            return 1;
          }
          return 0;
        });
      }
      setMeasureList(props.value ? props.value : []);
      setFilteredMeasureList(props.value ? props.value : []);
    },
    [props.value]
  );

  // useEffect((_) => {
  //   setReportList(props.reports.filter((r) => r.id != props.report.id));
  // }, []);

  // const importMeasuresFromReports = (_) => {
  //   if (
  //     reportsToImportMeasures != null &&
  //     Array.isArray(reportsToImportMeasures) &&
  //     reportsToImportMeasures.length > 0
  //   ) {
  //     let checkedToImportMeasures = checkToImportMeasures(reportsToImportMeasures);
  //     if (checkedToImportMeasures.length > 0) {
  //       notifyChanges(measureList.concat(reportsToImportMeasures[0].measures));
  //     }
  //   }
  // };

  // const checkToImportMeasures = (reports) => {
  //   let validMeasures = [];
  //   reports.forEach((report) => {
  //     if (Array.isArray(report.measures) && report.measures.length > 0) {
  //       report.measures.forEach((measure) => {
  //         // Check if measure is already in the measure list
  //         if (
  //           (props.measures == null ||
  //             (props.measures && props.measures.filter((m) => m.id == measure.id).length == 0)) &&
  //           checkIfColumnIsInTheDatasourceColumns(measure.columnA) &&
  //           checkIfColumnIsInTheDatasourceColumns(measure.columnB) &&
  //           checkIfFiltersAreInTheDatasourceColumns(measure.filters)
  //         ) {
  //           validMeasures.push(measure);
  //         }
  //       });
  //     }
  //   });
  //   return validMeasures;
  // };

  const checkIfColumnIsInTheDatasourceColumns = (column) => {
    if (column == null || column == "") {
      return true;
    } else {
      for (let i = 0; i < props.options.length; i++) {
        const datasourceColumn = props.options[i];
        if (column == datasourceColumn.value) {
          return true;
        }
      }
      return false;
    }
  };

  const checkIfFiltersAreInTheDatasourceColumns = (filters) => {
    if (filters == null || filters == "" || (Array.isArray(filters) && filters.length == 0)) {
      return true;
    } else {
      for (let k = 0; k < filters.length; k++) {
        const filter = filters[k];
        for (let i = 0; i < props.options.length; i++) {
          const datasourceColumn = props.options[i];
          if (filter.column == datasourceColumn.value) {
            return true;
          }
        }
        return false;
      }
    }
  };

  const notifyChanges = (changes) => {
    if (props.onChange) {
      props.onChange(changes);
    }
  };

  const searchMeasuresByTables = (e) => {
    const search = e.target.value.toLowerCase();
    if (search != null && search != "") {
      setFilteredMeasureList(
        measureList.filter(function (el) {
          if (el.name != null && el.name != "" && typeof el.name == "string") {
            if (el.name.toLowerCase().indexOf(search) != -1) {
              return true;
            }
          } else if (el.columnA != null && el.columnA != "" && typeof el.columnA == "string") {
            if (el.columnA.toLowerCase().indexOf(search) != -1) {
              return true;
            }
          } else if (el.columnB != null && el.columnB != "" && typeof el.columnB == "string") {
            if (el.columnB.toLowerCase().indexOf(search) != -1) {
              return true;
            }
          } else if (el.columnA != null && typeof el.columnA == "object") {
            if (
              el.columnA.columnA != null &&
              el.columnA.columnA != "" &&
              typeof el.columnA.columnA == "string" &&
              el.columnA.columnA.toLowerCase().indexOf(search) != -1
            ) {
              return true;
            } else if (
              el.columnA.columnB != null &&
              el.columnA.columnB != "" &&
              typeof el.columnA.columnB == "string" &&
              el.columnA.columnB.toLowerCase().indexOf(search) != -1
            ) {
              return true;
            }
          } else if (el.columnB != null && typeof el.columnB == "object") {
            if (
              el.columnB.columnA != null &&
              el.columnB.columnA != "" &&
              typeof el.columnB.columnA == "string" &&
              el.columnB.columnA.toLowerCase().indexOf(search) != -1
            ) {
              return true;
            } else if (
              el.columnB.columnB != null &&
              el.columnB.columnB != "" &&
              typeof el.columnB.columnB == "string" &&
              el.columnB.columnB.toLowerCase().indexOf(search) != -1
            ) {
              return true;
            }
          } else {
            return false;
          }
        })
      );
    } else {
      setFilteredMeasureList(measureList);
    }
  };

  return (
    <Wrapper>
      {/* <MeasureImporterForm>
        <MeasureImporterTitle>Import Measures</MeasureImporterTitle>
        <KSTKSelect
          id="report"
          placeholder={<div>Target Reports</div>}
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          options={reportList}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
          isDisabled={reportList == null || reportList.length == 0 ? true : false}
          onChange={(reps) => setReportsToImportMeasures(reps)}
        />
        <MeasureFormButtonWrapper>
          <Button onClick={importMeasuresFromReports}>Import</Button>
        </MeasureFormButtonWrapper>
      </MeasureImporterForm> */}
      <MeasureForm>
        <KSTKMeasureField
          options={props.options}
          value={measureFieldValue}
          onChange={setMeasureFieldValue}
        ></KSTKMeasureField>
        <MeasureFormButtonWrapper>
          <Button onClick={measureAddEdit}>Add/Edit</Button>
        </MeasureFormButtonWrapper>
      </MeasureForm>
      <MeasureItemList
        title="Measure List"
        columns={measureColumns}
        data={filteredMeasureList}
        onSelect={onMeasureSelect}
        onDeselect={onMeasureDeselect}
        onDelete={onMeasureDelete}
        searchFunction={searchMeasuresByTables}
      ></MeasureItemList>
    </Wrapper>
  );
}
