import { generateService, BASE_URL, handleErrors } from "./Util";
import { fetchAuthentication } from "./Client";

const service = generateService("organizations");

export const getOrganizations = service.get;

export const getDataFromDatasource = (orgId, schema, source, top) => {
  return fetch(`${BASE_URL}/organizations/${orgId}/getDatasourceData?schema=${schema}&source=${source}&top=${top}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  }).then(handleErrors);
};
