import {
  FETCHING_DATASOURCES,
  SEARCH_DATASOURCES,
  SELECT_DATASOURCE,
  UPSERT_SOURCE,
  REMOVE_SOURCE,
  SET_SOURCES,
  UPSERT_RELATION,
  REMOVE_RELATION,
  UPDATE_METADATA,
  CREATE_DATASOURCE,
  DELETE_DATASOURCE,
  UPDATE_DATASOURCE,
  UPSERT_VARIABLE,
  REMOVE_VARIABLE,
} from "../actions/DataSourceActions";

export default (
  state = {
    datasources: [],
    filteredDatasources: [],
    selectedDatasource: undefined,
  },
  action
) => {
  switch (action.type) {
    case CREATE_DATASOURCE:
      return {
        ...state,
        datasources: state.datasources.concat(action.payload),
        filteredDatasources: state.datasources.concat(action.payload),
        selectedDatasource: action.payload,
      };
    case FETCHING_DATASOURCES:
      return {
        ...state,
        datasources: action.payload,
        filteredDatasources: action.payload,
      };
    case SEARCH_DATASOURCES:
      return {
        ...state,
        filteredDatasources: state.datasources.filter((r) => {
          return (
            r.name.toLowerCase().indexOf(action.payload) > -1 ||
            r.description?.toLowerCase().indexOf(action.payload) > -1 ||
            r.tags?.toLowerCase().indexOf(action.payload) > -1
          );
        }),
      };
    case SELECT_DATASOURCE:
      return {
        ...state,
        selectedDatasource: state.datasources.find((r) => r.id === action.payload),
      };
    case UPDATE_DATASOURCE:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        }),
        filteredDatasources: state.filteredDatasources.map((d) => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        }),
      };
    case DELETE_DATASOURCE:
      return {
        ...state,
        datasources: state.datasources.filter((d) => d.id !== action.payload),
        filteredDatasources: state.filteredDatasources.filter((d) => d.id !== action.payload),
      };
    case UPSERT_SOURCE:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            const exists = d.resources.filter(
              (res) => res.name === action.payload.source.name && res.schema === action.payload.source.schema
            ).length;

            if (!exists) {
              d.resources.push({
                name: action.payload.source.name,
                schema: action.payload.source.schema,
              });
            }
            return d;
          } else {
            return d;
          }
        }),
      };
    case REMOVE_SOURCE:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            d.resources = d.resources.filter((r) => {
              if (r.name === action.payload.source.name && r.schema === action.payload.source.schema) {
                return false;
              } else {
                return true;
              }
            });
            return d;
          } else {
            return d;
          }
        }),
      };
    case SET_SOURCES:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            d.resources = action.payload.sources;
            d.sourceType = action.payload.sourceType;
            return d;
          } else {
            return d;
          }
        }),
      };
    case UPSERT_RELATION:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            const exists = d.relations.filter((rel) => rel.id === action.payload.relation.id).length;

            if (!exists) {
              d.relations.push(action.payload.relation);
            } else {
              const relationIndex = d.relations.findIndex((r) => r.id === action.payload.relation.id);
              d.relations.splice(relationIndex, 1, action.payload.relation);
            }
            return d;
          } else {
            return d;
          }
        }),
      };
    case REMOVE_RELATION:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            d.relations = d.relations.filter((r) => {
              if (JSON.stringify(r) === JSON.stringify(action.payload.relation)) {
                return false;
              } else {
                return true;
              }
            });
            return d;
          } else {
            return d;
          }
        }),
      };
    case UPDATE_METADATA:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            d.name = action.payload.metadata.name;
            d.description = action.payload.metadata.description;
            d.tags = action.payload.metadata.tags;
            return d;
          } else {
            return d;
          }
        }),
      };
    case UPSERT_VARIABLE:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId) {
            const exists =
              d.variables != null
                ? d.variables.filter((variable) => variable.variableName === action.payload.variable.variableName)
                    .length
                : false;

            if (!exists) {
              if (d.variables == null) {
                d.variables = [action.payload.variable];
              } else {
                d.variables.push(action.payload.variable);
              }
            } else {
              const variableIndex = d.variables.findIndex(
                (v) => v.variableName === action.payload.variable.variableName
              );
              d.variables.splice(variableIndex, 1, action.payload.variable);
            }
            return d;
          } else {
            return d;
          }
        }),
      };
    case REMOVE_VARIABLE:
      return {
        ...state,
        datasources: state.datasources.map((d) => {
          if (d.id === action.payload.datasourceId && d.variables != null) {
            d.variables = d.variables.filter((r) => {
              if (JSON.stringify(r) === JSON.stringify(action.payload.variable)) {
                return false;
              } else {
                return true;
              }
            });
            return d;
          } else {
            return d;
          }
        }),
      };
    default:
      return state;
  }
};
