import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

export default class KSTKDropdown extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      selected: this.props.selected ? this.props.selected : null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selected: this.props.selected,
      });
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onSelect(d) {
    this.props.onSelect(d);
    this.setState({
      selected: this.props.entryLabel ? this.props.entryLabel(d) : JSON.stringify(d).substr(0, 10),
    });
  }

  reset() {
    this.props.onSelect();
    this.setState({
      selected: this.props.label,
    });
  }

  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle color="dark" caret>
          {this.state.selected || this.props.label}
        </DropdownToggle>
        <DropdownMenu dark>
          {this.props.data &&
            this.props.data.length &&
            this.props.data.map((d, i) => {
              return (
                <DropdownItem onClick={(e) => this.onSelect(d)} key={i}>
                  {this.props.entryLabel ? this.props.entryLabel(d) : JSON.stringify(d).substr(0, 10)}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
