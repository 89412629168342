import React from "react";

export const ReportContext = React.createContext({
  report: null, //Array[0]: report being shown/edited
  selection: null, //selected chart data (filtering context)
  selectedChart: null, //selected chart id
  filter: () => {}, //filter report (on chart click)
  visualPanelIconClick: () => {}, //add chart to the layout/change chart type
  kpiClick: () => {}, //on chart click
  removeKPI: () => {}, //remove chart from layout (report)
  datasource: null, //report datasource
  selectedChartMetadata: null, //selected chart metadata
  setSelectedChartMetadata: () => {}, //update selected chart metadata
  setChartValue: () => {}, //update chart value
  pages: null, //pages metadata (list of pages with name, layout)
  selectedPage: null, //id of the selected page
  setSelectedPageMetadata: () => {},
  layout: null, //report page layout metadata (list of charts and their metadata - position, query metadata, etc...)
});
