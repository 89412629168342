import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "reactstrap";
import { throttle } from "lodash";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function KSTKCustomColumnsValues(props) {
  const [name, setName] = useState(props.name);
  const [customFunction, setCustomFunction] = useState(props.customFunction);

  const notify = throttle(props.onChange, 5000);

  const handleNameChange = (newName) => {
    setName(newName);
    let changeValue = {};
    changeValue.name = newName && newName != "" ? newName : "";
    changeValue.customFunction = customFunction && customFunction != "" ? customFunction : "";
    notify(changeValue);
  };

  const handleCustomFunctionChange = (newCustomFunction) => {
    setCustomFunction(newCustomFunction);
    let changeValue = {};
    changeValue.name = name && name != "" ? name : "";
    changeValue.customFunction = newCustomFunction && newCustomFunction != "" ? newCustomFunction : "";
    notify(changeValue);
  };

  return (
    <Wrapper>
      <Input placeholder="Name" onChange={(e) => handleNameChange(e.target.value)} value={props.name} />
      <Input
        placeholder="Function"
        onChange={(e) => handleCustomFunctionChange(e.target.value)}
        value={props.customFunction}
      />
    </Wrapper>
  );
}

export default KSTKCustomColumnsValues;
