import React from "react";
import styled from "styled-components";

const Label = styled.h1``;

export function KSTKLabel(props) {
  const getTextAlignment = (alignment) => {
    switch (alignment) {
      case "start":
        return "left";
      case "center":
        return "center";
      case "end":
        return "right";
      default:
        break;
    }
  };

  return (
    <Label
      style={{
        fontSize: props.metadata?.titleFontSize ? props.metadata.titleFontSize + "px" : "2em",
        fontFamily: props.metadata?.titleFontFamily
          ? props.metadata.titleFontFamily
          : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        color: props.metadata?.titleTextColor ? props.metadata.titleTextColor : null,
        backgroundColor: props.metadata?.titleBackgroundColor ? props.metadata.titleBackgroundColor : null,
        fontWeight: props.metadata?.titleFontBold ? 700 : 500,
        fontStyle: props.metadata?.titleFontItalic ? "italic" : "initial",
        textDecoration: props.metadata?.titleFontUnderline ? "underline" : "none",
        textAlign: props.metadata?.titleTextAlign ? getTextAlignment(props.metadata.titleTextAlign) : "left",
      }}
    >
      {props.metadata && props.metadata.title ? props.metadata.title : null}
    </Label>
  );
}
