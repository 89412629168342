export const FETCHING_REPORTS = "FETCHING_REPORTS";
export const SEARCH_REPORTS = "SEARCH_REPORTS";
export const SELECT_REPORTS = "SELECT_REPORTS";
export const CREATE_REPORT = "CREATE_REPORTS";
export const DELETE_REPORT = "DELETE_REPORTS";

export const fetchReports = reports => dispatch => {
  dispatch({
    type: FETCHING_REPORTS,
    payload: reports
  });
};

export const searchReports = search => dispatch => {
  dispatch({
    type: SEARCH_REPORTS,
    payload: search
  });
};

export const selectReport = id => dispatch => {
  dispatch({
    type: SELECT_REPORTS,
    payload: id
  });
};

export const createReport = report => dispatch => {
  dispatch({
    type: CREATE_REPORT,
    payload: report
  });
};

export const deleteReport = id => dispatch => {
  dispatch({
    type: DELETE_REPORT,
    payload: id
  });
};
