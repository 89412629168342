export const sqlTypeMapping = {
  text: "string",
  "double precision": "number",
  integer: "number"
};

export function getJavaScriptType(sqlType) {
  const conversion = sqlTypeMapping[sqlType];
  return conversion ? conversion : "text";
}
