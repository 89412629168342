//@flow

import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import styled from "styled-components";
import { Input } from "reactstrap";
import { v1 as uuid } from "uuid";

import { KSTKSelect } from "./KSTKSelect";
import KSTKFilterField from "./KSTKFilterField";

import type { KSTKFilterValues } from "./KSTKFilterField";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 38px auto 38px 38px 95px;
  grid-row-gap: 5px;
  grid-column-gap: 1%;
`;

const NewMeasureTitle = styled.h5`
  grid-column-start: span 5;
`;

const NameInput = styled(Input)`
  grid-column-start: span 5;
`;

const FiltersTitle = styled.h6`
  grid-column-start: span 5;
  align-self: end;
`;

const MeasureFilters = styled.div`
  grid-column-start: span 5;
  overflow: auto;
`;

const AllExceptTitle = styled.h6`
  grid-column-start: span 5;
  align-self: end;
`;

const MeasureAllExcept = styled.div`
  grid-column-start: span 5;
  overflow: auto;
`;

type KSTKMeasureValue = {
  id: string,
  name: string,
  aggregateA: string,
  columnA: string,
  operation: string,
  aggregateB: string,
  columnB: string,
  filters: ?KSTKFilterValues,
};

type KSTKMeasureFieldProps = {
  options: Array<Object>,
  value: KSTKMeasureValue,
  onChange: Function,
};

export default function KSTKMeasureField(props: KSTKMeasureFieldProps) {
  const aggregates = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
    { value: "count", label: "Count" },
    { value: "count_dist", label: "Count (Distinct)" },
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
  ];

  const operations = [
    { value: "+", label: "+" },
    { value: "-", label: "-" },
    { value: "/", label: "/" },
    { value: "*", label: "*" },
    { value: "||", label: "CONCAT" },
  ];

  const handleNameChange = (n) => {
    notifyChange({
      ...props.value,
      name: n,
    });
  };
  const getNameValue = (_) => {
    return props.value !== null ? props.value.name : "";
  };

  const handleAggregateAChange = (aggA) => {
    notifyChange({
      ...props.value,
      aggregateA: aggA ? aggA.value : null,
    });
  };
  const getAggregateAValue = (_) => {
    return aggregates.filter((a) => a.value === props.value?.aggregateA);
  };

  const handleColumnAChange = (colA) => {
    notifyChange({
      ...props.value,
      columnA: colA ? colA.value : null,
    });
  };
  const getColumnAValue = (_) => {
    if (props.value?.columnA != null) {
      if (typeof props.value?.columnA == "string") {
        return props.options ? props.options.filter((o) => o.value === props.value?.columnA) : [];
      } else {
        return props.options ? props.options.filter((o) => o.value.name === props.value?.columnA?.name) : [];
      }
    } else {
      return [];
    }
  };

  const handleOperationChange = (op) => {
    notifyChange({
      ...props.value,
      operation: op ? op.value : null,
    });
  };
  const getOperationValue = (_) => {
    return operations.filter((o) => o.value === props.value?.operation);
  };

  const handleAggregateBChange = (aggB) => {
    notifyChange({
      ...props.value,
      aggregateB: aggB ? aggB.value : null,
    });
  };
  const getAggregateBValue = (_) => {
    return aggregates.filter((a) => a.value === props.value?.aggregateB);
  };

  const handleColumnBChange = (colB) => {
    notifyChange({
      ...props.value,
      columnB: colB ? colB.value : null,
    });
  };
  const getColumnBValue = (_) => {
    if (props.value?.columnB != null) {
      if (typeof props.value?.columnB == "string") {
        return props.options ? props.options.filter((o) => o.value === props.value?.columnB) : [];
      } else {
        return props.options ? props.options.filter((o) => o.value.name === props.value?.columnB?.name) : [];
      }
    } else {
      return [];
    }
  };

  const handleFiltersChange = (filterValue: KSTKFilterValues) => {
    notifyChange({
      ...props.value,
      filters: filterValue,
    });
  };

  const handleAllExceptChange = (allExcept) => {
    notifyChange({
      ...props.value,
      allExcept: allExcept ? allExcept : null,
    });
  };
  const getAllExceptValue = (_) => {
    return props?.value?.allExcept;
  };
  // const createValue = (_): KSTKMeasureValue => {
  //   return {
  //     id: uuid(),
  //     name: "",
  //     aggregateA: "",
  //     columnA: "",
  //     operation: "",
  //     aggregateB: "",
  //     columnB: "",
  //     filters: null,
  //   };
  // };

  const notifyChange = (values) => {
    if (props.onChange) {
      if (!values.id) {
        values.id = uuid();
      }
      props.onChange(values);
    }
  };

  // let [value, setValue] = useState(props.value ? props.value : createValue());

  // useEffect(
  //   (_) => {
  //     setValue(props.value ? props.value : createValue());
  //   },
  //   [props.value]
  // );

  return (
    <Wrapper>
      <NewMeasureTitle>New/Edit Measure</NewMeasureTitle>
      <NameInput
        placeholder="Name"
        onChange={(e) => handleNameChange(e.target.value)}
        value={getNameValue()}
      ></NameInput>
      <KSTKSelect
        id="aggregateA"
        placeholder={<div>Aggregate A</div>}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        options={aggregates}
        onChange={(value) => handleAggregateAChange(value)}
        value={getAggregateAValue()}
      />
      <KSTKSelect
        id="columnA"
        placeholder={<div>Column A</div>}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        options={props.options}
        onChange={(value) => handleColumnAChange(value)}
        value={getColumnAValue()}
      />
      <KSTKSelect
        id="operation"
        placeholder={<div>Operation</div>}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        options={operations}
        onChange={(value) => handleOperationChange(value)}
        value={getOperationValue()}
      />
      <KSTKSelect
        id="aggregateB"
        placeholder={<div>Aggregate B</div>}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        options={aggregates}
        onChange={(value) => handleAggregateBChange(value)}
        value={getAggregateBValue()}
      />
      <KSTKSelect
        id="columnB"
        placeholder={<div>Column B</div>}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        options={props.options}
        onChange={(value) => handleColumnBChange(value)}
        value={getColumnBValue()}
      />
      <FiltersTitle>Measure Filters</FiltersTitle>
      <MeasureFilters>
        <KSTKFilterField
          options={props.options}
          onChange={handleFiltersChange}
          values={props.value?.filters}
          isDisabled={false}
        ></KSTKFilterField>
      </MeasureFilters>
      <AllExceptTitle>Measure All Except</AllExceptTitle>
      <MeasureAllExcept>
        <KSTKSelect
          id="allExceptSelect"
          placeholder={<div>All Except</div>}
          isMulti={true}
          isSearchable={true}
          options={props.options}
          onChange={(value) => handleAllExceptChange(value)}
          value={getAllExceptValue()}
        ></KSTKSelect>
      </MeasureAllExcept>
    </Wrapper>
  );
}

// export default KSTKMeasureField = forwardRef(KSTKMeasureField);
