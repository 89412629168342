import React, { useEffect, useState } from "react";
import KSTKCardList from "../components/KSTKCardList";
import * as ReportService from "../services/report.service";
import { getCategories } from "../services/category.service";
import { getAnalyticsReports } from "../services/analytics.report.service";

export default function ReportList(props) {
  const [reports, setReports] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    ReportService.getReports().then((data) => props.fetchReports(data));
    document.title = "KSTK Report Builder";
  }, []);

  useEffect(() => {
    if (props.reports != null && props.reports.length) {
      getCategories().then((cts) => {
        if (JSON.stringify(categories) != JSON.stringify(cts)) {
          setCategories(cts);
        }
        getAnalyticsReports().then((rps) => {
          getReportsByCategories(cts, rps);
        });
      });
    }
  }, [props.reports]);

  const getReportsByCategories = (cts, rps) => {
    let reportsByCategory = [];
    let reportsCopy = JSON.parse(JSON.stringify(props.reports));
    reportsCopy.forEach((rep) => {
      let filteredReport = rps.filter((r) => r.reportBuilderSrc == rep.id);
      if (filteredReport != null && filteredReport.length) {
        let filteredCat = cts.filter((c) => c.id == filteredReport[0].category);
        if (filteredCat != null && filteredCat.length) {
          rep.parsedCategory = filteredCat[0].name;
        } else {
          if (rep.metaReportReports != null) {
            rep.parsedCategory = "🚁 Meta Reports";
          } else {
            rep.parsedCategory = "Sem Categoria";
          }
        }
      } else {
        if (rep.metaReportReports != null) {
          rep.parsedCategory = "🚁 Meta Reports";
        } else {
          rep.parsedCategory = "Sem Categoria";
        }
      }
    });
    reportsCopy?.sort(function compareReports(a, b) {
      if (a.parsedCategory != null && b.parsedCategory != null) {
        if (a.parsedCategory == "Meta Reports" && b.parsedCategory != "Meta Reports") {
          return -1;
        }
        if (a.parsedCategory != "Meta Reports" && b.parsedCategory == "Meta Reports") {
          return 1;
        }
        if (a.parsedCategory == "Sem Categoria" && b.parsedCategory != "Sem Categoria") {
          return 1;
        }
        if (a.parsedCategory != "Sem Categoria" && b.parsedCategory == "Sem Categoria") {
          return -1;
        }
        if (a["parsedCategory"].localeCompare(b["parsedCategory"], "pt") == -1) {
          return -1;
        }
        if (a["parsedCategory"].localeCompare(b["parsedCategory"], "pt") == 1) {
          return 1;
        }
      }

      if (a["name"].localeCompare(b["name"], "pt") == -1) {
        return -1;
      }
      if (a["name"].localeCompare(b["name"], "pt") == 1) {
        return 1;
      }
    });
    if (JSON.stringify(reports) != JSON.stringify(reportsCopy)) {
      setReports(reportsCopy);
    }
  };

  const goToReport = (rep, mode) => {
    props.selectReport(rep.id);
    props.history.push({
      pathname: `/reports/${rep.id}`,
      search: `?mode=${mode}`,
    });
  };

  const createReport = () => {
    ReportService.createReport().then((report) => {
      props.createReport(report);
      goToReport(report, "edit");
    });
  };

  const deleteReport = (rep) => {
    ReportService.deleteReport(rep.id).then(() => {
      ReportService.getReports().then((data) => props.fetchReports(data));
    });
  };

  const duplicateReport = (rep) => {
    let reportOriginalName = rep.name;
    let duplicateReport = JSON.parse(JSON.stringify(rep));
    delete duplicateReport.id;
    delete duplicateReport.modifiedAt;
    duplicateReport.createdAt = new Date().getTime();
    for (let i = 1; i < props.reports.length; i++) {
      if (props.reports.find((r) => r.name === reportOriginalName + " (" + i + ")") == null) {
        duplicateReport.name = reportOriginalName + " (" + i + ")";
        break;
      }
    }
    ReportService.createReport(duplicateReport).then((report) => {
      props.createReport(report);
      ReportService.getReports().then((data) => props.fetchReports(data));
    });
  };

  return (
    <KSTKCardList
      title="Reports"
      metadata={reports}
      searchData={props.searchReports}
      delete={deleteReport}
      action={goToReport}
      create={createReport}
      duplicate={duplicateReport}
      view={true}
    />
  );
}
// export default class ReportList extends Component {
//   constructor(props) {
//     super(props);
//     this.goToReport = this.goToReport.bind(this);

//     getReports().then(data => this.props.fetchReports(data));
//   }

//   goToReport(rep) {
//     this.props.selectReport(rep.id);
//     this.props.history.push(`/reports/${rep.id}`);
//   }

//   createReport() {

//   }

//   render() {
//     return (
//       <KSTKCardList
//         metadata={this.props.reports}
//         searchData={this.props.searchReports}
//         action={this.goToReport}
//         create={this.createReport}
//       />
//     );
//   }
// }
