import { PUBLISH_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/NotificationActions";
import { v1 as uuid } from "uuid";
import produce from "immer";

export default (
  state = {
    notifications: [],
  },
  action
) => {
  switch (action.type) {
    case PUBLISH_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat({ ...action.payload, id: uuid() }),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: produce(state.notifications, (n) => {
          n.splice(
            state.notifications.findIndex((not) => not.id === action.payload),
            1
          );
        }),
      };
    default:
      return state;
  }
};
