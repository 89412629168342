import React, { useContext } from "react";
import styled from "styled-components";
import { KSTKSelect } from "./KSTKSelect";
import { Input, Button } from "reactstrap";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px 0px;
  input,
  select {
  }
`;

const InputFontSize = styled(Input)`
  width: 60px;
`;

const KSTKSelectFontFamily = styled(KSTKSelect)`
  width: 150px;
`;

const InputColor = styled(Input)`
  width: 35px;
`;

export function KSTKFontEditor(props) {
  const fontFamilies = [
    { value: "Arial", label: "Arial (sans-serif)" },
    { value: "Verdana", label: "Verdana (sans-serif)" },
    { value: "Tahoma", label: "Tahoma (sans-serif)" },
    { value: "Trebuchet MS", label: "Trebuchet MS (sans-serif)" },
    { value: "Times New Roman", label: "Times New Roman (serif)" },
    { value: "Georgia", label: "Georgia (serif)" },
    { value: "Garamond", label: "Garamond (serif)" },
    { value: "Courier New", label: "Courier New (monospace)" },
    { value: "Brush Script MT", label: "Brush Script MT (cursive)" },
  ];

  return (
    <div>
      <label htmlFor="font">Font</label>

      <React.Fragment>
        <Wrapper>
          <InputFontSize
            placeholder="Size"
            id="fontSize"
            name="fontSize"
            type="number"
            min={9}
            max={50}
            onChange={(e) => props.handleChange(e.target.value, props.target + "FontSize", props.field)}
            value={
              props.getValue(props.target + "FontSize", props.field) != null
                ? props.getValue(props.target + "FontSize", props.field)
                : ""
            }
          />
          <KSTKSelectFontFamily
            placeholder="Family"
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            options={fontFamilies}
            onChange={(e) => props.handleChange(e ? e.value : "", props.target + "FontFamily")}
            value={
              fontFamilies.filter((el) => el.value == props.getValue(props.target + "FontFamily", props.field)).length >
              0
                ? fontFamilies.filter((el) => el.value == props.getValue(props.target + "FontFamily", props.field))[0]
                : []
            }
          />
        </Wrapper>
        <Wrapper>
          <Button onClick={() => props.handleChange(true, props.target + "FontBold", props.field)}>
            <i className="fas fa-bold" />
          </Button>
          <Button onClick={() => props.handleChange(true, props.target + "FontItalic", props.field)}>
            <i className="fas fa-italic" />
          </Button>
          <Button onClick={() => props.handleChange(true, props.target + "FontUnderline", props.field)}>
            <i className="fas fa-underline" />
          </Button>
        </Wrapper>
      </React.Fragment>

      <label htmlFor="textColor">Text color</label>
      <InputColor
        id="textColor"
        name="textColor"
        placeholder="Text color"
        type="color"
        onChange={(e) => props.handleChange(e.target.value, props.target + "TextColor", props.field)}
        value={props.getValue(props.target + "TextColor", props.field)}
      />
      <label htmlFor="backgroundColor">Background color</label>
      <InputColor
        id="backgroundColor"
        name="backgroundColor"
        placeholder="Background color"
        type="color"
        onChange={(e) => props.handleChange(e.target.value, props.target + "BackgroundColor", props.field)}
        value={props.getValue(props.target + "BackgroundColor", props.field)}
      />
      {props.textAlignField == null || props.textAlignField == true ? (
        <div>
          <label htmlFor="textAlignment">Text alignment</label>
          <Wrapper>
            <Button onClick={() => props.handleChange("start", props.target + "TextAlign", props.field)}>
              <i className="fas fa-align-left" />
            </Button>
            <Button onClick={() => props.handleChange("center", props.target + "TextAlign", props.field)}>
              <i className="fas fa-align-center" />
            </Button>
            <Button onClick={() => props.handleChange("end", props.target + "TextAlign", props.field)}>
              <i className="fas fa-align-right" />
            </Button>
          </Wrapper>
        </div>
      ) : null}
    </div>
  );
}
