import { combineReducers } from "redux";
import ReportReducer from "./ReportReducer";
import DataSourceReducer from "./DataSourceReducer";
import NotificationReducer from "./NotificationReducer";

export default combineReducers({
  ReportReducer,
  DataSourceReducer,
  NotificationReducer,
});
